import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import FormProperties from './Party_FormProperties'

import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'
import CommonTranslationText from '../../common/CommonTranslationText'

const { form: { CngViewForm },
	button: { CngSecondaryButton }
} = components

function EditPage({ history, showNotification, props }) {
	const { id } = useParams()
	const commonText = CommonTranslationText();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngViewForm
					fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
					showNotification={showNotification}
					bodySection={
						<>
							<FormProperties.Fields
								disabled={true}
								showNotification={showNotification}
								id={id}
							/>
							<Grid container spacing={3}>
								<Grid item>
									<CngSecondaryButton
										onClick={() => { history.push(pathMap.SCO_INV_PRINCIPAL_MAPPING_PARTIES_LIST) }}>
										{commonText.back}
									</CngSecondaryButton>
								</Grid>
							</Grid>
						</>
					}
					formikProps={FormProperties.formikProps}
					fetch={{
						id: id,
						url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`
					}}

				/>
			</Grid>

		</Grid>

	)

}

export default EditPage
