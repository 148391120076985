import { components } from 'cng-web-lib';
import React from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import pathMap from 'src/paths/PathMap_SCO_InvMgmt';
import FormProperties from './Buyer_FormProperties';
import BuyerTranslationText from './BuyerTranslationText'

const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
	const translatedTextObject = BuyerTranslationText()

    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            shouldDisableEnterToSubmit
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            buttonText={translatedTextObject.create}
            create={{
				url: SCOInvMgmtApiUrls.CREATE_BUYER,
				successRedirect: pathMap.SCO_INV_BUYER_LIST
			}}
        />
    )

}

export default CreatePage
