import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'


const AddressTranslationText = () => {
    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const addressCountry = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADDRESS_COUNTRY)
    const addressState = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADDRESS_STATE)
    const addressStreet = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADDRESS_STREET)
    const addressCity = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADDRESS_CITY)
    const addressPostCode = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADDRESS_POST_CODE)
    const exceedCharLimitMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)

    return {
        addressCountry,
        addressState,
        addressStreet,
        addressCity,
        addressPostCode,
        exceedCharLimitMsg
    }
}

export default AddressTranslationText
