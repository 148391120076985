import { Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import { getPartyID } from 'src/store/intelligent-advisory'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components

const {
   filter: {
     EQUAL, IN
   }
} = constants

function StockAllocationTable(props) {
      const {
            showNotification,
      } = props
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: 'operatorId',
                  title: "Operator",
                  customLookup: () => {
				  return fetchCustomLookup(
					    `${CecAccountApiUrls.GET_ACCOUNT_BY_PARTYID}`,
					    {
                                          //To only display the CEC Accounts based Active and inActive Status
                                          customData: { 
                                                baseFilterDTO:{
                                                      filterProperties: [
                                                            {
                                                                  //Filter to only display active and inActive status
                                                                  fieldName : 'status',
                                                                  operatorType: IN.toUpperCase(),
                                                                  value1: 'ACTV|IATV'
                                                            }
                                                      ],
                                                      filterType: 'AND',
                                                      sortProperties: []
                                                } 
                                          },
                                  },
					    'content', 
					    'displayName', // label accessor
					    'id', // value accessor
					    (error) => console.error(error)
				    )
			      }

            },
            {
                  field: 'locationId',
                  title: "Location",
                  customLookup: () => {
                  	return fetchCustomLookup(
                  		`${SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST}`,
                  		{
                  		    "partyId": getPartyID()
                  		},
                  		'content',
                  		'locationName', // label accessor
                  		'id', // value accessor
                  		(error) => console.error(error)
                  	)
                  }
            },
            {
                  field: 'dutyStatusId',
                  title: "Duty Status",
                  customLookup: () => {
                  	return fetchCustomLookup(
                  		`${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                  		{
                           "codeType" : "INV_DUTY_STATUS"
                        },
                  		'content',
                  		'name', // label accessor
                  		'id', // value accessor
                  		(error) => console.error(error)
                  	)
                  }
            },
            {
                  field: "permitTypeId",
                  title: "Permit Type",
                  customLookup: () => {
				  return fetchCustomLookup(
					    `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
					    {
                           "codeType" : "INV_PERMIT_TYPE"
					    },
					    'content',
					    'name', // label accessor
					    'id', // value accessor
					    (error) => console.error(error)
				    )
				  }

            },
            {
                  field: "permitNumber",
                  title: "Permit Number",
            },
            {
                  field: "buyerId",
                  title: "Buyer",
                  customLookup: () => {
				    return fetchCustomLookup(
					    `${SCOInvMgmtApiUrls.GET_BUYER}`,
					    {},
					    'content',
					    'buyerName', // label accessor
					    'id',// value accessor
					    (error) => console.error(error)
				    )
			    }
            },
            {
                  field: "quantity",
                  title: "Quantity",
                  filtering: false,
            },
            {
                  field: 'createdDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
           <Grid container spacing={3}>
               <CngCrudTable
                   fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                   columns={columns}
                   fetch={{ url: SCOInvMgmtApiUrls.GET_ALLOCATION_LIST }}
                   authoriseActions= {{
                         execute: (actions) => {
                               return actions
                         }
                   }}
                   fetchFilters={[{ field: 'stockId', operator: EQUAL, value: props.stockID }]}
                   idAccessor="id"
                   notification={notification}
               />
           </Grid>
      )
}

export default StockAllocationTable