import { constants, Yup } from 'cng-web-lib'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import Namespace from 'src/constants/locale/Namespace'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const FIELDS_MAX_LENGTH = {
	LOCATION_NAME: 255,
}

export function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)

	return Yup.object({
		locationName: Yup.string().nullable(false).required(requiredMessage).max(FIELDS_MAX_LENGTH.LOCATION_NAME, exceedCharMsg)
	})
}

