import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { default as React } from 'react'
import { Upload } from 'react-feather'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import SKUTranslationText from './SKUTranslationText'
import { getPartyID } from 'src/store/intelligent-advisory'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants

function TablePage(props) {
      const {
            location: { pathname },
            showNotification,
            history
      } = props
      const translatedTextObject = SKUTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const partyId = getPartyID();

      const columns = [
            {
                  field: 'categoryId',
                  title: translatedTextObject.skuCategory,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_CATEGORY}`,
                              {
                                    'partyId': partyId
                              },
                              'content',
                              'categoryName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "skuNum",
                  title: translatedTextObject.skuNumber
            },
            {
                  field: "skuDesc",
                  title: translatedTextObject.skuDesc
            },
            {
                  field: 'supplierId',
                  title: translatedTextObject.supplier,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_SUPPLIER}`,
                              {
                                    'partyId': partyId
                              },
                              'content',
                              'supplierName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: 'createdDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              {partyId &&
                                    <CngCrudTable
                                          actions={[
                                                {
                                                      icon: () => <Upload style={{ color: "#5E81F4" }} />,
                                                      isFreeAction: true,
                                                      onClick: (event) => history.push(pathMap.SCO_INV_SKU_UPLOAD)
                                                }]}
                                          {...props}
                                          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                          columns={columns}
                                          addRoute={`${pathname}/create`}
                                          del={{ url: SCOInvMgmtApiUrls.DELETE_SKU }}
                                          editRoute={`${pathname}/edit`}
                                          fetchFilters={[{ field: 'partyId', operator: EQUAL, value: partyId }]}
                                          fetch={{ url: SCOInvMgmtApiUrls.GET_SKU }}
                                          idAccessor="id"
                                          notification={notification}
                                          viewRoute={`${pathname}/view`}
                                    />
                              }
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage