import { Box } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'

import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import StockTranslationText from './StockTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      },
      CngTabs
} = components

const {
      filter: {
            EQUAL, IN,
      }
} = constants

function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = StockTranslationText();

      // Duty Unpaid Columns
      // New Columns - Pack size, Whole Qty, LooseQty, Literage, Storage Temp
      const dutyUnpaidColumns = [
            {
                  field: 'principalId',
                  title: "Principal",
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${CecAccountApiUrls.GET_ACCOUNT_BY_PARTYID}`,
                              {
                                    //To only display the CEC Accounts based Active and inActive Status
                                    customData: { 
                                          baseFilterDTO:{
                                                filterProperties: [
                                                      {
                                                            //Filter to only display active and inActive status
                                                            fieldName : 'status',
                                                            operatorType: IN.toUpperCase(),
                                                            value1: 'ACTV|IATV'
                                                      }
                                                ],
                                                filterType: 'AND',
                                                sortProperties: []
                                          } 
                                    },
                              },
                              'content',
                              'displayName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "lotNumber",
                  title: "Lot Number"
            },
            {
                  field: 'skuNum',
                  title: "SKU Number",
            },
            {
                  field: 'skuDesc',
                  title: "SKU Description",
            },
            {
                  field: "packSize",
                  title: "Pack Size",
                  filtering: false
            },
            {
                  field: "wholeQuantity",
                  title: "Whole Quantity",
                  filtering: false
            },
            {
                  field: "quantity",
                  title: "Loose Quantity",
                  filtering: false
            },
            {
                  field: "literage",
                  title: "Literage",
                  filtering: false
            },
            {
                  field: "storageTemperature",
                  title: "Storage Temperature",
                  filtering: false
            },
            {
                  field: 'updatedDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      // Duty Paid Columns 
      // Reduced to 5 columns
      const dutyPaidColumns = [
            {
                  field: 'principalId',
                  title: "Principal",
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${CecAccountApiUrls.GET_ACCOUNT_BY_PARTYID}`,
                              {
                                    //To only display the CEC Accounts based Active and inActive Status
                                    customData: {
                                          baseFilterDTO:{
                                                filterProperties: [
                                                      {
                                                            //Filter to only display active and inActive status
                                                            fieldName : 'status',
                                                            operatorType: IN.toUpperCase(),
                                                            value1: 'ACTV|IATV'
                                                      }
                                                ],
                                                filterType: 'AND',
                                                sortProperties: []
                                          }
                                    },
                              },
                              'content',
                              'displayName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "lotNumber",
                  title: "Lot Number"
            },
            {
                  field: 'skuNum',
                  title: "SKU Number",
            },
            {
                  field: 'skuDesc',
                  title: "SKU Description",
            },
            {
                  field: "quantity",
                  title: "Loose Quantity",
                  filtering: false
            },
            {
                  field: 'updatedDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
            <Box>
                  <CngTabs
                        headerColor='primary'
                        tabs={[
                              {
                                    tabName: translatedTextsObject.dutyUnpaid,
                                    tabContent: (
                                          <CngCrudTable
                                                {...props}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={dutyUnpaidColumns}
                                                fetch={{ url: SCOInvMgmtApiUrls.GET_STOCK_DUTY_UNPAID }}
                                                idAccessor="id"
                                                notification={notification}
                                                viewRoute={`${pathname}/view`}
                                                editRoute={`${pathname}/edit`}
                                          />
                                    )
                              },
                              {
                                    tabName: translatedTextsObject.dutyPaid,
                                    tabContent: (
                                          <CngCrudTable
                                                {...props}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={dutyPaidColumns}
                                                fetch={{ url: SCOInvMgmtApiUrls.GET_STOCK_DUTY_PAID }}
                                                idAccessor="id"
                                                notification={notification}
                                                viewRoute={`${pathname}/view`}
                                                editRoute={`${pathname}/edit`}
                                          />
                                    )
                              }
                        ]}
                  />
            </Box>
      )


}

export default TablePage