import {Grid, Box, CircularProgress } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import StockTranslationText from '../StockTranslationText'

const { 
	button: { CngPrimaryButton, CngSecondaryButton },
} = components



function ButtonComponents({ history, shouldShowPrimaryButtonCircularProgress}) {
	const fieldsTranslatedTextObject = StockTranslationText();
	
    return(
		<Grid container xs={12} sm={12} justify="flex-start">
			<Grid container xs={9} sm={12} justify="flex-end" >
				<Box>
					<CngPrimaryButton 
						style={{ marginRight: '10px' }}
						type ="submit"
						endIcon={
							shouldShowPrimaryButtonCircularProgress ? (
								<CircularProgress color='inherit' size={10} />
							) : null
						}
					>
						{fieldsTranslatedTextObject.update}									
					</CngPrimaryButton>
																	
				</Box>
																
				<Box pr={2}>
					{/* Empty on purpose */}
				</Box>

				<Box>
					<CngSecondaryButton
						onClick={() => { history.push(pathMap.SCO_INV_STOCK_INVENTORY_LIST) }}
					>
						{fieldsTranslatedTextObject.cancel}
					</CngSecondaryButton>
				</Box>
			</Grid>

		</Grid>
    )

}

export default ButtonComponents