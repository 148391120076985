import { Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import { getPartyID } from 'src/store/intelligent-advisory'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants

function StockHistoryTable(props, id) {
      const {
            showNotification,
      } = props
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = PrincipalMappingTranslationText()

      const columns = [
            {
                  field: 'updatedDate',
                  title: translatedTextsObject.updatedDatetime,
                  defaultSort: 'desc',
                  filtering: false,
                  render: (rowData) => {
                        if (rowData) {
                              const dateTime = new Date(rowData.updatedDate); //retrieve DateTime
                              const convertDateTimeISO = new Date(dateTime - (dateTime.getTimezoneOffset() * 60000)).toISOString(); //convert to ISO format
                              const convertDate = convertDateTimeISO?.split('T')[0]?.split("-").reverse().join("/"); //Change dateformat from 'yyyy-mm-dd' to 'dd/mm/yyyy'
                              const convertTime = convertDateTimeISO.replace("T", " ").substring(10, 19); //select time only.
                              return convertDate + convertTime;
                        }
                  }
            },
            {
                  field: 'movementTypeId',
                  title: translatedTextsObject.movementType,
                  filtering: false,
                  sorting: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {},
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
            },
            {
                  field: 'quantity',
                  title: translatedTextsObject.quantity,
                  filtering: false,
                  sorting: false,
            },
            {
                  field: 'fromLocationId',
                  title: translatedTextsObject.fromLocation,
                  filtering: false,
                  sorting: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST}`,
                              {
                              },
                              'content',
                              'locationName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
            },
            {
                  field: 'toLocationId',
                  title: translatedTextsObject.toLocation,
                  filtering: false,
                  sorting: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST}`,
                              {
                              },
                              'content',
                              'locationName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
            },
            {
                  field: 'statusId',
                  title: "Status",
                  filtering: false,
                  sorting: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {},
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
            }
      ]
      return (
            <Grid container spacing={3}>
                  <CngCrudTable
                        {...props}
                        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                        columns={columns}
                        fetch={{ url: SCOInvMgmtApiUrls.GET_STOCK_MOVEMENT, 'stockId': props.id, }}
                        authoriseActions={{
                              execute: (actions) => {
                                    return actions
                              }
                        }}
                        fetchFilters={[{ field: 'stockId', operator: EQUAL, value: props.id }]}
                        idAccessor="id"
                        notification={notification}
                  />
            </Grid>
      )
}

export default StockHistoryTable