import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'
import CommonTranslationText from '../common/CommonTranslationText'

const CategoryTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const skuCatNameCol = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_CAT_NAME_COL)
    const skuCatDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_CAT_DETAILS)
    const categoryName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CATEGORY_NAME)
    const categoryAttributes = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CATEGORY_ATTRIBUTES)
    const attributeName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ATTRIBUTE_NAME)
    const dataType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DATA_TYPE)
    const addAttribute = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADD_ATTRIBUTE)
    const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)
    const exceedCharLimitMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
    const noAttrMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.NO_ATTR_MSG)

    return {
        moduleTitle,
        skuCatNameCol,
        skuCatDetails,
        categoryName,
        categoryAttributes,
        attributeName,
        dataType,
        addAttribute,
        partyName,
        exceedCharLimitMsg,
        noAttrMsg,
        ...commonText
    }
}

export default CategoryTranslationText
