import { Box, Button, Card, CardContent, Grid, IconButton } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants, useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SKUTranslationText from '../SKUTranslationText'

const UOM_CODE_TYPE = 'INV_UOM'
const DEAFULT_BASE_UOM_CODE = 'EA'

const {
    form: {
        field: { CngTextField, CngSelectField, CngLookupAutocompleteField },
    },
    CngGridItem
} = components


const { NotificationType } = constants

const UomDisplay = (props) => {
    const { isViewOnly, isCreate, setFieldValue, showNotification, uomList, appendUom, removeUom, disabled } = props;
    const { fetchRecords } = useServices();
    const fieldsTranslatedTextObject = SKUTranslationText();
    const [uomOptions, setUomOptions] = useState([]);

    useEffect(() => {
        getUomOptions();
    }, [])

    const getUomOptions = () => {
        const onSuccess = (response) => {
            const options = response.content.map((uom) => {
                if (uom.code === DEAFULT_BASE_UOM_CODE) {
                    // default base uom for Create SKU
                    if (isCreate) {
                        setFieldValue("baseUomId", uom.id);
                    }
                }
                return { text: uom.name, value: uom.id }
            })
            setUomOptions(options);
        }

        fetchRecords.execute(
            `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${UOM_CODE_TYPE}`,
            {},
            onSuccess,
            () => {
                showNotification(NotificationType.ERROR, "Failed to fetch UOM options")
            }
        )
    }

    const handleAddUom = () => {
        const uomItem = {
            uomId: "",
            quantity: ""
        }
        appendUom(uomItem);
    };

    const handleRemoveUom = (index) => {
        removeUom(index);
    };

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.uom} />
                    <Grid container spacing={2}>
                        <CngGridItem xs={12} sm={4}>
                            <CngSelectField
                                disabled={!isCreate}
                                name="baseUomId"
                                label={fieldsTranslatedTextObject.baseUom}
                                items={uomOptions}
                                required
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={4}>
                            <CngTextField
                                type="number"
                                disabled={true}
                                name="baseUomQty"
                                label={fieldsTranslatedTextObject.quantity}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}/>
                        

                        <CngGridItem xs={12} sm={4}>
                            <CngLookupAutocompleteField
                                key = {props.wholeUomKey}
                                name='wholeUomId'
                                label={'Whole UOM'}
                                lookupProps={
                                    {
                                    url: `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${UOM_CODE_TYPE}`,
                                    label: "name",
                                    value: "id"
                                }
                                }
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={4}>
                            <CngTextField
                                type="number"
                                disabled={false}
                                name="wholeUomQty"
                                label={fieldsTranslatedTextObject.quantityToBaseUom}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}>
                            <Card>
                                <CardContent>
                                    <Grid container>
                                        <Box fontSize={10} paddingBottom={2} width={'100%'}>
                                            <AccordionHeaderComponent hideMandatory={true} title={fieldsTranslatedTextObject.alternateUom} />
                                        </Box>
                                        <Grid container spacing={2}>
                                            {isViewOnly && uomList.length === 0 &&
                                                <CngGridItem xs={12}>
                                                    <p>{"There are no alternate UOMs configured for this SKU."} </p>
                                                </CngGridItem>
                                            }
                                            {uomList.map((uom, index) => {
                                                return (
                                                    <CngGridItem xs={12} sm={12} key={uom.key}>
                                                        <Card className="inner-card-group" >
                                                            <Grid container spacing={2}>
                                                                <CngGridItem xs={12} sm={4}>
                                                                    <CngSelectField
                                                                        disabled={disabled}
                                                                        name={`uomList[${index}].uomId`}
                                                                        label={fieldsTranslatedTextObject.uom}
                                                                        items={uomOptions}
                                                                        required
                                                                    />
                                                                </CngGridItem>
                                                                <CngGridItem xs={12} sm={4}>
                                                                    <CngTextField
                                                                        type="number"
                                                                        disabled={disabled}
                                                                        name={`uomList[${index}].quantity`}
                                                                        label={fieldsTranslatedTextObject.quantityToBaseUom}
                                                                        required
                                                                    />
                                                                </CngGridItem>
                                                                <CngGridItem xs={12} sm={4}>
                                                                    {!isViewOnly &&
                                                                        <Box style={{ textAlign: 'right' }}>
                                                                            <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveUom(index)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    }
                                                                </CngGridItem>
                                                            </Grid>
                                                        </Card>
                                                    </CngGridItem>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {!isViewOnly &&
                                    <Button
                                        variant="contained"
                                        color="default"
                                        fullWidth
                                        disabled={disabled}
                                        startIcon={<AddBoxIcon />}
                                        className={'accordion-footer-add-component'}
                                        onClick={() => handleAddUom()}
                                    >
                                        <Box style={{ textAlign: 'left' }}>
                                            <Box >
                                                {fieldsTranslatedTextObject.addAlternateUom}
                                            </Box>

                                        </Box>
                                    </Button>
                                }
                            </Card>
                        </CngGridItem>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

}

export default UomDisplay;