import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'

const WarehouseTranslationText = () => {
      const { translate } = useTranslation(Namespace.SCO_INV_MGMT)
      const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
      const warehouseName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAREHOUSE_NAME)
      const warehouseTags = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAREHOUSE_TAGS)
      const warehouseDetailsTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAREHOUSE_DETAILS_TITLE)
      const warehouseAddressTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAREHOUSE_ADDRESS_TITLE)
      const warehouseStorageLocationTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAREHOUSE_STORAGE_LOC_TITLE)
      const locationName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_NAME)
      const parentLocation = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARENT_LOCATION)
      const locationTags = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_TAGS)
      const locationCode = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_CODE)
      const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)
      const locationRefId = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_REF_ID)

      return {
            moduleTitle,
            warehouseName,
            warehouseTags,
            warehouseDetailsTitle,
            warehouseAddressTitle,
            warehouseStorageLocationTitle,
            locationName,
            parentLocation,
            locationTags,
            locationCode,
            partyName,
            locationRefId
      }
}

export default WarehouseTranslationText