import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

export function makeValidationSchema(translate) {

	const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)

	return Yup.object({
		locationList: Yup.array(
			Yup.object().shape({
				locationId: Yup.string().nullable().required(requiredMessage)
			})
		)
	})
}

