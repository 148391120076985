import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from '../warehouse/Warehouse_FormProperties'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import { Grid } from '@material-ui/core'


const {
      form: { CngViewForm },
      button: { CngSecondaryButton },
} = components


function ViewForm({ history, showNotification, id }) {
      const [loading, setLoading] = useState(false);


      return (

            <CngViewForm
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  history={history}
                  showNotification={showNotification}
                  bodySection={
                        <>
                              <FormProperties.Fields
                                    showNotification={showNotification}
                                    id={id}
                                    loading={loading}
                                    disabled={true}
                                    isAdmin={true}
                              />

                              <Grid container spacing={3}>
                                    <Grid item>
                                          <CngSecondaryButton
                                                onClick={() => { history.push(pathMap.SCO_INV_ADMIN_WAREHOUSE_LIST) }}>
                                                Back
                                          </CngSecondaryButton>
                                    </Grid>
                              </Grid>
                        </>
                  }

                  formikProps={
                        FormProperties.formikProps
                  }
                  toClientDataFormat={FormProperties.toClientDataFormat}
                  toServerDataFormat={FormProperties.toServerDataFormat}
                  fetch={{
                        url: SCOInvMgmtApiUrls.ADMIN_WAREHOUSE_BY_ID,
                        id: id
                  }}
                  id={id}

            />

      )

}

export default ViewForm