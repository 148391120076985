import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'
import FormProperties from './NonStorageLocation_FormProperties'

const { form: { CngViewForm },
	button: { CngPrimaryButton, CngSecondaryButton },
} = components

function ViewPage({ showNotification }) {
	const { id } = useParams()
	const history = useHistory()

	return (
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			showNotification={showNotification}
			bodySection={
				<>
					<FormProperties.Fields
						disabled={true}
						showNotification={showNotification}
						id={id}
					/>

					<Grid container spacing={3}>
						<Grid item>
							<CngPrimaryButton style={{ marginRight: '10px' }}
								onClick={() => history.push(`${InvSCOPathMap.SCO_INV_NONSTORAGE_LIST}/edit/${id}`)}>
								Edit
							</CngPrimaryButton>
							<CngSecondaryButton
								onClick={() => {
									history.push(InvSCOPathMap.SCO_INV_NONSTORAGE_LIST)
								}
								}>
								Back
							</CngSecondaryButton>
						</Grid>
					</Grid>
				</>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{
				id: id,
				url: `${SCOInvMgmtApiUrls.GET_NONSTORAGE_BY_ID}`
			}}

		/>
	)

}

export default ViewPage
