import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { components, constants, useTranslation, Yup } from 'cng-web-lib'
import { default as React } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SKUTranslationText from './SKUTranslationText'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants
const { FormState } = constants
const {
    form: {
        field: { CngSelectField },
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        CngForm
    },
    CngGridItem,
    CngDialog,
    button: { CngPrimaryButton, CngSecondaryButton }

} = components



const AttributeDialog = ({ isOpen, setFieldValue, attributeList, setIsOpen, attrOptions, filteredAttrOptions, setNewFilteredOptions }) => {
    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);

    const fieldsTranslatedTextObject = SKUTranslationText();
	const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)

    const onSubmit = (values) => {
        let chosenAttrVal = values.chosenAttr;
        let chosenAttr = attrOptions.find(attr => attr.id == chosenAttrVal);
        const attrItem = {
            configDTO: chosenAttr,
            value: ""
        }
        const newList = [...attributeList, attrItem];
        setFieldValue("attributeList", newList);
        setNewFilteredOptions(newList);
        setIsOpen(false);
    };

    return (
        <CngDialog
            open={isOpen}
            fullWidth
            maxWidth='md'
            customDialogContent={
                <Paper>
                    <Box m={3} mt={3}>
                        <Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.selectAttribute}</Typography>
                        <Box mt={2}>

                            <CngForm
                                formikProps={{
                                    initialValues: { chosenAttr: '' },
                                    validationSchema: Yup.object({ chosenAttr: Yup.string().required(requiredMessage) }),
                                    onSubmit: onSubmit
                                }}
                                formState={FormState.COMPLETED}
                                renderButtonSection={() => <></>}
                                renderBodySection={() =>
                                    <FormFields
                                        filteredAttrOptions={filteredAttrOptions}
                                        setIsOpen={setIsOpen}
                                        fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                                    />
                                }
                            />

                        </Box>
                    </Box>
                </Paper>
            }

        />
    );
}


const FormFields = ({ filteredAttrOptions, setIsOpen, fieldsTranslatedTextObject }) => {
    const { submitForm } = useFormikContext()

    return (
        <Grid container alignItems='center' justify='center'>
            <CngGridItem xs={12}>
                <CngSelectField
                    label={fieldsTranslatedTextObject.attribute}
                    name="chosenAttr"
                    items={filteredAttrOptions}
                    required
                />
            </CngGridItem>
            <Box mt={2}>
                <CngPrimaryButton style={{ marginRight: '10px' }}
                    type="button"
                    onClick={submitForm}
                >
                    Add
                </CngPrimaryButton>
                <CngSecondaryButton
                    onClick={() => setIsOpen(false)}>
                    {fieldsTranslatedTextObject.cancel}
                </CngSecondaryButton>
            </Box>
        </Grid>
    );
}


export default AttributeDialog;