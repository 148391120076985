import { useTranslation } from 'cng-web-lib'
import SCOInvReportKeys from 'src/constants/locale/key/SCOInvReport'
import Namespace from 'src/constants/locale/Namespace'
import CommonTranslationText from '../common/CommonTranslationText'

const InvReportTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_REPORT)
    const principal = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_FIELD_PRINCIPAL)
    const dutyStatus = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_FIELD_DUTY_STATUS)
	const warehouse = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_FIELD_WAREHOUSE)
    const fileNameFileType = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_FILE_NAME_FILE_TYPE)
    const createdDate = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_CREATED_DATE)
    const status = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.REPORT_STATUS)
    const uploadComplete = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.UPLOAD_COMPLETED)
    const uploadFailed = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.UPLOAD_FAILED)

    return{
        principal,
        dutyStatus,
        warehouse,
        fileNameFileType,
        createdDate,
        status,
        uploadComplete,
        uploadFailed,
        ...commonText
    }



}
export default InvReportTranslationText