import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import Namespace from 'src/constants/locale/Namespace'
import DefaultLocConfigTranslationText from './DefaultLocConfigTranslationText'
import FormProperties from './DefLocConfig_FormProperties'
import { makeValidationSchema } from './DefLocConfig_ValidationSchema'

const { form: { CngForm } } = components
const { FormState, NotificationType } = constants

function EditPage({ showNotification }) {
    const translatedTextObject = DefaultLocConfigTranslationText()
    const { fetchRecords, updateRecord } = useServices();
    const [data, setData] = useState([])
    const [fetchingData, setFetchingData] = useState(true)
    const [submittingData, setSubmittingData] = useState(false)

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        const onSuccess = (response) => {
            setData(response)
            setFetchingData(false);
        }

        fetchRecords.execute(
            SCOInvMgmtApiUrls.GET_DEF_LOC_CONFIG,
            {},
            onSuccess,
            () => {
                setFetchingData(false);
                showNotification(NotificationType.ERROR, translatedTextObject.fetchError)
            }
        )
    }

    const onSubmit = (values) => {
        setSubmittingData(true);

        const onSuccess = (response) => {
            setSubmittingData(false);
            if (response.errorMessages) {
                showNotification(NotificationType.ERROR, response.errorMessages[0]);
            }
            showNotification(NotificationType.SUCCESS, translatedTextObject.updateSuccess);
        }
        const onError = () => {
            setSubmittingData(false);
            showNotification(NotificationType.ERROR, translatedTextObject.updateError);
        }
        updateRecord.execute(
            SCOInvMgmtApiUrls.UPDATE_DEF_LOC_CONFIG,
            values,
            onSuccess,
            onError
        )
    };

    return (
        <>
            <CngForm
                formState={fetchingData ? FormState.LOADING : FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                bodySection={
                    <FormProperties.Fields
                        showNotification={showNotification}
                        submittingData={submittingData}
                    />
                }
                renderButtonSection={() => (
                    <></>
                )}
                formikProps={{
                    initialValues: data,
                    validationSchema: () => makeValidationSchema(translate),
                    onSubmit: onSubmit
                }}
                toClientDataFormat={FormProperties.toClientDataFormat}
                toServerDataFormat={FormProperties.toServerDataFormat}

            />
        </>
    )

}

export default EditPage
