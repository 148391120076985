import { components, useServices } from 'cng-web-lib';
import React, { useEffect, useState } from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import pathMap from 'src/paths/PathMap_SCO_InvMgmt';
import FormProperties from './SKU_FormProperties';
import SKUTranslationText from './SKUTranslationText';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
    const translatedTextObject = SKUTranslationText()
    const [cloneData, setCloneData] = useState([])

    const location = useLocation()
    const id = location.state?.id

    const { securedSendRequest } = useServices()

    const reqData = { "baseFilterDTO": {
        "filterType": "AND",
        "filterProperties": [
          {
            "fieldName": "id",
            "operatorType": "EQUAL",
            "value1": id
          }
        ],
        "sortProperties": []
      }
    }
    
    useEffect( () => {

        if(id == undefined) {
            return;
        }
        const onSuccess = (data) => {
            setCloneData(data.data.content[0]);
        }
    
        securedSendRequest.execute(
            'post',
            SCOInvMgmtApiUrls.GET_SKU_BY_ID,
            reqData,
            onSuccess,
            { 
               headers : {
                Authorization: `Bearer `+localStorage.getItem("userToken")
               }
            }
          )
    }, [id])
    

    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    data={cloneData}
                    disabled={false}
                    showNotification={showNotification}
                    isCreate={true}
                    />
            }
            buttonText={translatedTextObject.create}
            formikProps={FormProperties.formikProps}
            create={{
                url: SCOInvMgmtApiUrls.CREATE_SKU,
                successRedirect: pathMap.SCO_INV_SKU_LIST
            }}
        />
    )

}

export default CreatePage
