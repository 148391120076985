import { constants, Yup } from 'cng-web-lib'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import Namespace from 'src/constants/locale/Namespace'
import { makeAddressValidationSchema } from '../common/makeAddressValidationSchema'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const FIELDS_MAX_LENGTH = {
	WAREHOUSE_NAME: 255,
}

export function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
	let isWarehouse = true
	return Yup.object({
		warehouseName: Yup.string().nullable(false).required(requiredMessage).max(FIELDS_MAX_LENGTH.WAREHOUSE_NAME, exceedCharMsg),
		warehouseTags: Yup.string().nullable(false).max(255, "255 Characters limit per Tag."),
		address: makeAddressValidationSchema(translate, isWarehouse)
	})
}

