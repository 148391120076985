import {
    components
} from 'cng-web-lib';
import React from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import FormProperties from './NonStorageLocation_FormProperties';
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'



const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            buttonText="Create"
            create={{
                url: SCOInvMgmtApiUrls.ADD_NONSTORAGE_LOCATION,
                successRedirect: InvSCOPathMap.SCO_INV_NONSTORAGE_LIST
            }}
        />
    )

}

export default CreatePage
