import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import ScheduleInfoValidationSchema from 'src/views/vesselschedule/manageschedule/validationschema/ScheduleInfoValidationSchema_bk'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const FIELDS_MAX_LENGTH = {
	SKU_NUM: 255,
	SUPPLIER_SKU_NUM: 255,
	SKU_DESC: 255,
	ATTR_VALUE: 255
}


export function makeValidationSchema(translate) {
	let uomList = []
	let wholeUomGlobalId = ""
	let baseUomId = ""
	Yup.addMethod(Yup.array, 'uniqueList', function (message) {
		return this.test('uniqueList', message, function (values) {
			let list = []
			if (values) {
				values.forEach((elem) => {
					if (list.includes(elem.uomId)) {
						uomList.push(elem.uomId)
					}
					else {
						list.push(elem.uomId)
					}
					if (list.length === values.length) {
						uomList = []
					}
				})
			}
			return true;
		})
	})
	Yup.addMethod(Yup.string, 'uniqueUom', function (message) {
		return this.test('uniqueUom', message, function (val) {
			return !uomList.includes(val)
		})
	})

	Yup.addMethod(Yup.number, 'uniqueWholeUom', function(message) {
		return this.test('uniqueWholeUom', message, function(val) {
			wholeUomGlobalId = val
			return true
		})
	})

	Yup.addMethod(Yup.string, 'checkAlternateUomToWholeUom', function(message) {
		return this.test('checkAlternateUomToWholeUom', message, function(val) {
			if(Number(val) == wholeUomGlobalId)  {
				return false
			} else {
				return true
			}
		})
	})

	const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	const exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
	const duplicateAltUomMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUPLICATE_ALT_UOM_MSG)

	return Yup.object({
		categoryId: Yup.string().nullable().required(requiredMessage),
		skuNum: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.SKU_NUM, exceedCharMsg),
		supplierSkuNum:  Yup.string().nullable().max(FIELDS_MAX_LENGTH.SUPPLIER_SKU_NUM, exceedCharMsg),
		supplierId:  Yup.string().nullable(),
		skuDesc:  Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.SKU_DESC, exceedCharMsg),
		baseUomId: Yup.string().nullable().required(requiredMessage),
		wholeUomId: Yup.number().nullable().uniqueWholeUom("unique"),
		wholeUomQty: Yup.number().integer("Must be an integer")
			.when("wholeUomId", {
			is: (wholeUom) => wholeUom != null,
			then: Yup.number().integer("Must be an integer").required(requiredMessage).min(1, "Must be larger than 0"),
		}),
		attributeList: Yup.array(
			Yup.object().shape({
				value: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.ATTR_VALUE, exceedCharMsg)
			})
		),
		uomList: Yup.array(
			Yup.object().shape({
				uomId: Yup.string().nullable().required(requiredMessage)
				.uniqueUom(duplicateAltUomMsg),
				quantity: Yup.number().integer("Must be an integer").min(2, "Must be larger than 1").required(requiredMessage)
			})
		)
		.uniqueList()
	})
}

