import { Yup } from 'cng-web-lib'

const FIELDS_MAX_LENGH = {
      MAX_200: 200,
      MAX_50: 50,
}


const decimalsAllowedRegax = /^[0-9]\d*(\.\d{1,4})?$/


function CostDetailsSchema() {
    const mandatoryErrMsg = 'Required/Mandatory'
    const decimalValidationMsg = "Please input a number value not more than 4 decimals​";
      
    return Yup.object({
      currencyCode: Yup.string().required(mandatoryErrMsg).nullable(mandatoryErrMsg),
      
      productCost: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("productCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),
                                
      importHandlingCost: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("importHandlingCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),

      freightCost: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("freightCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),

      insuranceCost: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("insuranceCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),
      
      overheadCost: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("insuranceCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),

      customDuty: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("insuranceCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),

      exciseDuty: Yup.number().positive(decimalValidationMsg).min(0).nullable()
                                .typeError(decimalValidationMsg).test("insuranceCost", decimalValidationMsg, (value) => decimalsAllowedRegax.test(value)),                    
      
      taxCode: Yup.string().required(mandatoryErrMsg).nullable(mandatoryErrMsg),

      })
}

export default CostDetailsSchema