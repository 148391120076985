import { Card, CardContent, Grid } from '@material-ui/core'

import {
      components,
      constants,
      useTranslation
} from 'cng-web-lib'

import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import React from 'react'
import NonStorageTranslationText from '../nonstorage/NonStorageTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants

function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const translatedTextObject = NonStorageTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: "partyId",
                  title: translatedTextObject.partyName,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "locationName",
                  title: translatedTextObject.locationName
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    fetchFilters={[
                                          {
                                                field: 'systemLocationCode',
                                                operator: EQUAL,
                                                value: 'NSTOR'
                                          }
                                    ]}
                                    fetch={{ url: SCOInvMgmtApiUrls.ADMIN_LOCATION_URL }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage