import { components } from 'cng-web-lib';
import React from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import pathMap from 'src/paths/PathMap_SCO_InvMgmt';
import FormProperties from './Category_FormProperties';
import CategoryTranslationText from './CategoryTranslationText'

const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
    const translatedTextObject = CategoryTranslationText()

    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            buttonText={translatedTextObject.create}
            formikProps={FormProperties.formikProps}
            create={{
                url: SCOInvMgmtApiUrls.CREATE_CATEGORY,
                successRedirect: pathMap.SCO_INV_CATEGORY_LIST
            }}
        />
    )

}

export default CreatePage
