import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { useServices, components } from 'cng-web-lib'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import React, { useEffect, useState } from 'react'
import WarehouseTranslationText from './WarehouseTranslationText'
import { makeValidationSchema } from './WarehouseLocation_ValidationSchema'

const {
	card: { CngSimpleCardHeader },
	form: { field: { CngTextField, CngSelectField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
	locationCode: 'Storage',
	locationName: '',
	locationTagId: [],
	parentLocationId: '',
	locationTagsDisplay: [],
	locReferenceId: ''
})

const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

function Fields({ id, isViewOnly, isEdit, isAdd }) {
	const fieldsTranslatedTextObject = WarehouseTranslationText()
	const { fetchRecords } = useServices();
	const [warehouseName, setWarehouseName] = useState('')

	useEffect(() => {
		getWarehouseName(id)
	}, [id])


	const getWarehouseName = (id) => {
		const onSuccess = (response) => {
			setWarehouseName(response.content[0].warehouseName);
		}

		const onError = (error) => {
			console.log("Error:[" + JSON.stringify(error) + "]")
		}

		fetchRecords.execute(
			SCOInvMgmtApiUrls.GET_WAREHOUE_BY_ID,
			{
				filters: [
					{
						field: 'id',
						operator: 'EQUAL',
						value: id
					}
				]
			},
			onSuccess,
			onError
		)
	}


	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={fieldsTranslatedTextObject.warehouseStorageLocationTitle} />
			<Divider />
			<Card>
				<CardContent>
					<CngGridItem xs={12}>
						<Grid container spacing={2}>
							<CngGridItem xs={4} sm={4} shouldHide={isAdd}>
								<CngTextField name="locReferenceId"
									label={fieldsTranslatedTextObject.locationRefId}
									disabled={true}
								/>
							</CngGridItem>


							<CngGridItem xs={4} sm={4}>
								<CngTextField name="locationCode"
									label={fieldsTranslatedTextObject.locationCode}
									disabled={true}
									value={"Storage"}
								/>
							</CngGridItem>
							<CngGridItem xs={4} sm={4}>
								<CngTextField name="locationName"
									label={fieldsTranslatedTextObject.locationName}
									disabled={isViewOnly}
									required
								/>
							</CngGridItem>
							<CngGridItem xs={4} sm={4}>
								<CngLookupAutocompleteField
									name="locationTagId"
									disabled={isViewOnly}
									label={fieldsTranslatedTextObject.locationTags}
									multiple={true}
									freeSolo
									lookupProps={{
										url: SCOInvMgmtApiUrls.GET_WAREHOUSE_TAGS,
										label: (record) => {
											return `${record.tagTitle}`
										},
										value: (record) => {
											return `${record.tagTitle}`
										},
										filters: [
											{
												field: 'systemLocationCode',
												operator: 'EQUAL',
												value: 'STOR'
											}
										]
									}}
								/>
							</CngGridItem>
							<CngGridItem xs={4} sm={4}>
								<CngSelectField
									name="parentLocationId"
									label={fieldsTranslatedTextObject.parentLocation}
									disabled={isEdit || isViewOnly}
									fetch={{
										url: `${SCOInvMgmtApiUrls.GET_PARENTLOCATION_BY_WAREHOUSE_ID}`,
										textAccessor: "locationName",
										valueAccessor: "locationId",
										body: {
											warehouseId: id
										}

									}
									}
								/>
							</CngGridItem>
							<CngGridItem xs={4} sm={4}>
								<CngTextField name="warehouseName"
									label={fieldsTranslatedTextObject.warehouseName}
									disabled={true}
									value={warehouseName}
								/>
							</CngGridItem>
							<CngGridItem xs={4} sm={4} shouldHide={true}>
								<CngTextField name="warehouseId"
									label={"Warehouse id"}
									disabled={true}
									value={id}
								/>
							</CngGridItem>
						</Grid>



					</CngGridItem>
				</CardContent>
			</Card >
		</Card >
		//* End JSX ------------------------------------------------------------

	)

}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties