import { Card, CardContent, Grid, Chip } from '@material-ui/core'

import {
      components,
      constants,
      useTranslation
} from 'cng-web-lib'

import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import React from 'react'
import WarehouseTranslationText from '../warehouse/WarehouseTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components



function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const translatedTextObject = WarehouseTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: "partyId",
                  title: translatedTextObject.partyName,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "locReferenceId",
                  title: translatedTextObject.locationRefId,
                  filtering: false
            },
            {
                  field: "warehouseName",
                  title: translatedTextObject.warehouseName
            },
            {
                  field: "warehouseTags",
                  title: translatedTextObject.warehouseTags,
                  filtering: false,
                  sorting: false,
                  render: (rowData) => {
                        let whTags = rowData.warehouseTags;
                        return whTags.map((x) => {
                              return <Chip key={x.tag.id} label={x.tag.tagTitle} />
                        })
                  }
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    fetch={{ url: SCOInvMgmtApiUrls.ADMIN_WAREHOUSE_URL }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage