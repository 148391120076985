import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

const FIELDS_MAX_LENGTH = {
    SKU_NUM: 255,
    SUPPLIER_SKU_NUM: 255,
    SKU_DESC: 255
}


export function makeValidationSchema(translate) {
    let skuNumDuplicateList = []
    let supplierSkuNumDuplicateList = []

    const checkSkuNum = (elem, skuNumList, values) => {
        if (skuNumList.includes(elem.skuNum)) {
            skuNumDuplicateList.push(elem.skuNum)
        }
        else {
            skuNumList.push(elem.skuNum)
        }
        if (skuNumList.length === values.length) {
            skuNumDuplicateList = []
        }

    }

    const checkSupplierSkuNum = (elem, supplierSkuNumList, values) => {
        if (elem.supplierSkuNum) {
            if (supplierSkuNumList.includes(elem.supplierSkuNum)) {
                supplierSkuNumDuplicateList.push(elem.supplierSkuNum)
            }
            else {
                supplierSkuNumList.push(elem.supplierSkuNum)
            }
            if (supplierSkuNumList.length === values.length) {
                supplierSkuNumDuplicateList = []
            }
        }
    }

    Yup.addMethod(Yup.array, 'uniqueList', function (message) {
        return this.test('uniqueList', message, function (values) {
            let skuNumList = []
            let supplierSkuNumList = []

            if (values) {
                values.forEach((elem) => {
                    checkSkuNum(elem, skuNumList, values)
                    checkSupplierSkuNum(elem, supplierSkuNumList, values)
                })
            }
            return true;
        })
    })

    Yup.addMethod(Yup.string, 'uniqueSkuNum', function (message) {
        return this.test('uniqueSkuNum', message, function (val) {
            return !skuNumDuplicateList.includes(val)
        })
    })

    Yup.addMethod(Yup.string, 'uniqueSupplierSkuNum', function (message) {
        return this.test('uniqueSupplierSkuNum', message, function (val) {
            return !supplierSkuNumDuplicateList.includes(val)
        })
    })

    const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
    const exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
    const duplicateSkuNumMsg = "SKU number cannot be duplicate."
    const duplicateSupplierSkuNumMsg = "Supplier SKU number cannot be duplicate."

    return Yup.object({
        data: Yup.array(
            Yup.object().shape({
                categoryId: Yup.string().nullable().required(requiredMessage),
                skuNum: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.SKU_NUM, exceedCharMsg)
                    .uniqueSkuNum(duplicateSkuNumMsg),
                supplierSkuNum: Yup.string().nullable().max(FIELDS_MAX_LENGTH.SUPPLIER_SKU_NUM, exceedCharMsg)
                    .uniqueSupplierSkuNum(duplicateSupplierSkuNumMsg),
                supplierId: Yup.string().nullable(),
                skuDesc: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.SKU_DESC, exceedCharMsg),
                baseUomId: Yup.string().nullable().required(requiredMessage)
            })
        ).uniqueList("")
    })
}

