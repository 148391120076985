import { Card, CardContent, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { components, useServices, constants } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from "react-hook-form"
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import SKUTranslationText from '../SKUTranslationText'
import { makeValidationSchema } from './ConfirmSkuDetails_ValidationSchema'
import { ConfirmDetailsDisplay } from './ConfirmSkuDetails_Display'
import { getPartyID } from 'src/store/intelligent-advisory'

const {
    form: {
        field: { CngTextField, CngSelectField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,

} = components
const { NotificationType,
    filter: {
        EQUAL
    }
} = constants

const DEFAULT_INITIAL_VALUES = {
    id: "",
    categoryId: "",
    skuNum: "",
    supplierSkuNum: "",
    supplierId: "",
    skuDesc: "",
    baseUomId: "",
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}
const UOM_CODE_TYPE = 'INV_UOM'
const numOfRecordsOnPage = 20;
const INITIAL_PAGE_NUM = 1;
const FETCH_DROPDOWN_OPTIONS_CALL_NUM = 3;

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { loading, history, validRecordsCount, doNotProceedErrorMsg, showNotification, recordsCreated } = props;
    const fieldsTranslatedTextObject = SKUTranslationText();
    const { fetchRecords } = useServices();
    const { getValues } = useFormContext();
    let values = getValues();
    const { errors, setFieldValue, submitForm } = useFormikContext();
    const [page, setPage] = useState(INITIAL_PAGE_NUM);
    const [totalPageCount, setTotalPageCount] = useState(INITIAL_PAGE_NUM);
    const [recordsToDisplay, setRecordsToDisplay] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [baseUomOptions, setBaseUomOptions] = useState([]);
    const [fetchOptionsLoading, setFetchOptionsLoading] = useState(FETCH_DROPDOWN_OPTIONS_CALL_NUM);
    const { fields: data } = useFieldArray({ name: "data", keyName: 'key' });
    const [warningDialogOpen, setWarningDialogOpen] = useState(false)
    const [discardWarningDialogOpen, setDiscardWarningDialogOpen] = useState(false)
    const partyId = getPartyID();

    const partyIdFilterArr = (partyIdFilter) => {
        return [
            {
                field: 'partyId',
                operator: EQUAL,
                value: partyIdFilter
            }
        ]
    }

    useEffect(() => {
        let pageCount = Math.ceil(data.length / numOfRecordsOnPage);
        setTotalPageCount(pageCount);
        displaySkuRecords(page);
    }, [data])

    useEffect(() => {
        setFetchOptionsLoading(FETCH_DROPDOWN_OPTIONS_CALL_NUM);
        if (partyId) {
            getCategories();
            getSuppliers();
            getBaseUoms();
        }
    }, [partyId, recordsCreated])

    useEffect(() => {
        displaySkuRecords(page);
    }, [categoryOptions, supplierOptions, baseUomOptions, fetchOptionsLoading])

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0
    }

    const onUpdate = () => {
        //put this equal here because sometimes form is not having right array
        if (values.data.length === data.length && !isEmpty(errors)) {
            setWarningDialogOpen(true)
        } else {
            submitForm();
        }
    }

    const handleRemoveSku = (index) => {
        const list = [...data];
        list.splice(index, 1);
        setFieldValue("data", list);
    };

    const getCategories = () => {
        const onSuccess = (response) => {
            const options = response.content.map((cat) => {
                return { text: cat.categoryName, value: cat.id }
            })
            setCategoryOptions(options);
            setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
        }

        fetchRecords.execute(
            SCOInvMgmtApiUrls.GET_CATEGORY,
            {
                filters: partyIdFilterArr(partyId)
            },
            onSuccess,
            () => {
                setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
                showNotification(NotificationType.ERROR, "Failed to fetch Category options")
            }
        )
    }

    const getSuppliers = () => {
        const emptyVal = [{ text: "", value: null }];
        const onSuccess = (response) => {
            const options = response.content.map((supplier) => {
                return { text: supplier.supplierName, value: supplier.id }
            })
            const newOptions = emptyVal.concat(options);
            setSupplierOptions(newOptions);
            setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
        }

        fetchRecords.execute(
            SCOInvMgmtApiUrls.GET_SUPPLIER,
            {
                filters: partyIdFilterArr(partyId)
            },
            onSuccess,
            () => {
                setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
                showNotification(NotificationType.ERROR, "Failed to fetch Supplier options")
            }
        )

    }

    const getBaseUoms = () => {
        const onSuccess = (response) => {
            const options = response.content.map((uom) => {
                return { text: uom.name, value: uom.id }
            })
            setBaseUomOptions(options);
            setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
        }

        fetchRecords.execute(
            `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${UOM_CODE_TYPE}`,
            {},
            onSuccess,
            () => {
                setFetchOptionsLoading(prevFetchOptions => prevFetchOptions - 1)
                showNotification(NotificationType.ERROR, "Failed to fetch UOM options")
            }
        )
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        displaySkuRecords(value);
    };

    /*Have to add all these onChange due to the values not changing in data, and upon deletion, the new values are not reflected */
    const handleSkuDescChange = (event, index) => {
        const list = data;
        list[index].skuDesc = event.target.value;
        setFieldValue("data", list);
    }

    const handleCategoryIdChange = (event, index) => {
        const list = data;
        list[index].categoryId = event.target.value;
        setFieldValue("data", list);
    }

    const handleSupplierIdChange = (event, index) => {
        const list = data;
        list[index].supplierId = event.target.value;
        setFieldValue("data", list);
    }

    const handleBaseUomIdChange = (event, index) => {
        const list = data;
        list[index].baseUomId = event.target.value;
        setFieldValue("data", list);
    }

    const handleSupplierSkuNumChange = (event, index) => {
        const list = data;
        list[index].supplierSkuNum = event.target.value;
        setFieldValue("data", list);
    }

    const handleSkuNumChange = (event, index) => {
        const list = data;
        list[index].skuNum = event.target.value;
        setFieldValue("data", list);
    }

    const displaySupplierOrCategoryNotExistsMsg = (skuRecord) => {
        let msgs = [];
        if (recordsCreated) {
            if (categoryOptions && !categoryOptions.some(categoryId => categoryId == skuRecord.categoryId)) {
                msgs.push("Select another category. The category previously selected does not exist anymore.");
            }
            if (skuRecord.supplierId && supplierOptions && !supplierOptions.some(supplierId => supplierId == skuRecord.supplierId)) {
                msgs.push("Select another supplier. The supplier previously selected does not exist anymore.");
            }
        }
             
        return msgs.map(msg =>
            <p style={{ color: '#FF808B' }}>
                {msg}
            </p>)
    }

    const displaySkuRecords = (page) => {
        if (fetchOptionsLoading === 0) {
            let firstIndex = (page - 1) * numOfRecordsOnPage;
            let lastIndex = (page * numOfRecordsOnPage) - 1;
            if (lastIndex >= data.length) {
                lastIndex = data.length - 1;
            }

            let array = [];

            for (let index = firstIndex; index <= lastIndex; index++) {
                array.push(
                    <CngGridItem xs={12} sm={12} key={data[index].key}>
                        <Card>
                            <CardContent>
                                <IconButton aria-label="remove" onClick={() => handleRemoveSku(index)}>
                                    <CloseIcon />
                                </IconButton>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.skuDetails} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngSelectField
                                            name={`data[${index}].categoryId`}
                                            label={fieldsTranslatedTextObject.skuCategory}
                                            items={categoryOptions}
                                            onChange={(e) => handleCategoryIdChange(e, index)}
                                            required
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name={`data[${index}].skuNum`}
                                            label={`${fieldsTranslatedTextObject.skuNumber}`}
                                            onChange={(e) => handleSkuNumChange(e, index)}
                                            required
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name={`data[${index}].supplierSkuNum`}
                                            label={fieldsTranslatedTextObject.supplierSku}
                                            onChange={(e) => handleSupplierSkuNumChange(e, index)}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngSelectField
                                            name={`data[${index}].supplierId`}
                                            label={fieldsTranslatedTextObject.supplier}
                                            items={supplierOptions}
                                            onChange={(e) => handleSupplierIdChange(e, index)}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name={`data[${index}].skuDesc`}
                                            key={data[index].key}
                                            label={fieldsTranslatedTextObject.skuDesc}
                                            onChange={(e) => handleSkuDescChange(e, index)}
                                            required
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngSelectField
                                            name={`data[${index}].baseUomId`}
                                            label={fieldsTranslatedTextObject.baseUom}
                                            items={baseUomOptions}
                                            onChange={(e) => handleBaseUomIdChange(e, index)}
                                            required
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={12}>
                                        {
                                            displaySupplierOrCategoryNotExistsMsg(data[index])
                                        }
                                        {data[index].errorMessages &&
                                            <>
                                                {data[index].errorMessages.map((errorMsg) => {
                                                    return (
                                                        <p style={{ color: '#FF808B' }}>
                                                            {errorMsg}
                                                        </p>
                                                    )
                                                })}
                                            </>
                                        }
                                    </CngGridItem>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CngGridItem>
                )
            }

            setRecordsToDisplay(array);
        }

    }

    return (
        <>
            <ConfirmDetailsDisplay
                loading={loading}
                fetchOptionsLoading={fetchOptionsLoading}
                doNotProceedErrorMsg={doNotProceedErrorMsg}
                recordsCreated={recordsCreated}
                validRecordsCount={validRecordsCount}
                dataLength={data.length}
                recordsToDisplay={recordsToDisplay}
                onUpdate={onUpdate}
                warningDialogOpen={warningDialogOpen}
                discardWarningDialogOpen={discardWarningDialogOpen}
                setDiscardWarningDialogOpen={setDiscardWarningDialogOpen}
                setWarningDialogOpen={setWarningDialogOpen}
                handlePageChange={handlePageChange}
                totalPageCount={totalPageCount}
                page={page}
                history={history}
            />
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties