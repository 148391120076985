import { constants, Yup } from 'cng-web-lib'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import Namespace from 'src/constants/locale/Namespace'

const FIELDS_MAX_LENGTH = {
    STREET: 1000,
    CITY: 255,
    STATE_OTHER: 255,
    POST_CODE: 20
}
const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

export const addressDefaultValues = {
    street: '',
    city: '',
    postCode: '',
    stateOther: '',
    stateId: null,
    countryId: null,
    stateCode: '',
    countryCode: '',
    state: '',
};

export const makeAddressValidationSchema = (translate, isWarehouse) => {
    let exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
    let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)

    return Yup.object().shape({
        countryCode: isWarehouse ? Yup.string().required(requiredMessage).nullable() : Yup.string().nullable(),
        state: Yup.string().nullable().max(FIELDS_MAX_LENGTH.STATE_OTHER, exceedCharMsg)
            .when('countryCode', {
                is: (countryCode) => countryCode && countryCode.length > 0,
                then: Yup.string().required(requiredMessage),
                otherwise: Yup.string().nullable()
            }),
        street: Yup.string().nullable().max(FIELDS_MAX_LENGTH.STREET, exceedCharMsg)
            .when('countryCode', {
                is: (countryCode) => countryCode && countryCode.length > 0,
                then: Yup.string().required(requiredMessage),
                otherwise: Yup.string().nullable()
            }),
        city: Yup.string().nullable().max(FIELDS_MAX_LENGTH.CITY, exceedCharMsg),
        postCode: Yup.string().nullable().max(FIELDS_MAX_LENGTH.POST_CODE, exceedCharMsg),
    })

}