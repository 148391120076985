import { Card, CardContent, Grid, Box } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockTranslationText from './StockTranslationText'
import StockAllocationTable from './StockAllocationTable'
const {
    form: {
        field: { CngTextField, CngSelectField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    principal: "",
    skuNumber: "",
    skuDesc: "",
    lotNumber: "",
    quantity: "",
    mfgDate: "",
    expDate: "",
    asnNumber: "",
    asnDate: "",
    grnNumber: "",
    grnDate: "",
    stockDetails: "",
    stockHistory: "",
    skuDetails: "",
    stockAllocations: "",
    operator: "",
    location: "",
    dutyStatus: "",
    permitType: "",
    permitNumber: "",
    buyer: "",
    updatedDate: "",
    operationType: "",
    origin: "",
    destination: "",
    sku: {
        skuNum: "",
        categoryName: "",
        supplierSkuNum: "",
        supplierName: ""
    }

}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const fieldsTranslatedTextObject = StockTranslationText();
    const stockId = props.id;

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.stockDetails} hideMandatory />
                                </Box>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name="principal"
                                            label={`${fieldsTranslatedTextObject.principal}`}
                                            disabled={disabled}
                                            required={true}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="quantity"
                                            label={`${fieldsTranslatedTextObject.quantity}`}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="lotNumber"
                                            label={fieldsTranslatedTextObject.lotNumber}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="mfgDate"
                                            label={fieldsTranslatedTextObject.manufacturingDate}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="expDate"
                                            label={fieldsTranslatedTextObject.expiryDate}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="asnNumber"
                                            label={fieldsTranslatedTextObject.asnNumber}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="asnDate"
                                            label={fieldsTranslatedTextObject.asnDate}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="grnNumber"
                                            label={fieldsTranslatedTextObject.grnNumber}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="grnDate"
                                            label={fieldsTranslatedTextObject.grnDate}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>

                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.skuDetails} hideMandatory />
                                </Box>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name="sku.categoryName"
                                            label={`${fieldsTranslatedTextObject.skuCategory}`}
                                            disabled={disabled}

                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="sku.skuNum"
                                            label={`${fieldsTranslatedTextObject.skuNumber}`}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="sku.supplierSkuNum"
                                            label={`${fieldsTranslatedTextObject.supplierSku}`}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="sku.supplierName"
                                            label={fieldsTranslatedTextObject.supplier}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="sku.skuDesc"
                                            label={fieldsTranslatedTextObject.skuDesc}
                                        />
                                    </CngGridItem>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.stockAllocations} hideMandatory />
                                <StockAllocationTable stockID = {stockId} />
                            </Grid>
                        </CardContent>
                    </Card>

                </CngGridItem>

            </Grid>
        </>

    )
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
})

export default FormProperties


