import { Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import { getPartyID } from 'src/store/intelligent-advisory'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'


const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants

function StockAllocationTable(props) {
      const {
            showNotification,
      } = props
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = PrincipalMappingTranslationText()

      const columns = [
            {
                  field: 'locationId',
                  title: translatedTextsObject.location,
                  filtering: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST}`,
                              {

                              },
                              'content',
                              'locationName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: 'dutyStatusId',
                  title: translatedTextsObject.dutyStatus,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    "codeType": "INV_DUTY_STATUS"
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "permitTypeId",
                  title: translatedTextsObject.permitType,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    "codeType": "INV_PERMIT_TYPE"
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }

            },
            {
                  field: "permitNumber",
                  title: translatedTextsObject.permitNumber,
            },
            {
                  field: "buyerId",
                  title: translatedTextsObject.buyer,
                  filtering: false,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.GET_BUYER}`,
                              {},
                              'content',
                              'buyerName', // label accessor
                              'id',// value accessor
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "quantity",
                  title: translatedTextsObject.quantity,
                  filtering: false,
            },
            {
                  field: 'createdDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
            <Grid container spacing={3}>
                  <CngCrudTable
                        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                        columns={columns}
                        fetch={{ url: SCOInvMgmtApiUrls.GET_ALLOCATION_LIST }}
                        authoriseActions={{
                              execute: (actions) => {
                                    return actions
                              }
                        }}
                        fetchFilters={[{ field: 'stockId', operator: EQUAL, value: props.stockID }]}
                        idAccessor="id"
                        notification={notification}
                  />
            </Grid>
      )
}

export default StockAllocationTable