import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'

const {
    form: {
        field: { CngTextField, CngSelectField },
    },
    card: {
        CngSimpleCardHeader
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    accountCompanyId: "",
    accountPartyId: ""
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, customFieldsProps, partyId, ...props }) {
    console.log('this is party id in formproperties ' + partyId)
    const fieldsTranslatedTextObject = PrincipalMappingTranslationText();

    return (
        <Card>
            <CngSimpleCardHeader title={fieldsTranslatedTextObject.principalMappingForm} />
            <CardContent>
                <Grid container spacing={2}>
                    <CngGridItem xs={4} sm={4} >
                        <CngSelectField
                            name="accountCompanyId"
                            type="number"
                            label={fieldsTranslatedTextObject.principalCompanyId}
                            disabled={false}
                            required
                            fetch={{
                                url: CecAccountApiUrls.GET_ACCOUNT,
                                textAccessor: 'displayName',
                                valueAccessor: 'id',
                                body: {
                                    partyId: partyId,
                                    status: 'actv'
                                  }
                            }
                            }
                        />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4} >
                        <CngSelectField
                            name="accountPartyId"
                            type="number"
                            label={fieldsTranslatedTextObject.principalPartyId}
                            disabled={false}
                            required
                            fetch={{
                                url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                                textAccessor: 'name',
                                valueAccessor: 'id'
                            }
                            }
                        />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4} shouldHide={true}>
                        <CngTextField
                            name="partyId"
                            type="number"
                            label={fieldsTranslatedTextObject.partyId}
                            disabled={true}
                        //value={partyId}
                        />
                    </CngGridItem>
                </Grid>
            </CardContent>
        </Card>
    )
}


const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields
})

export default FormProperties


