import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOInvMgmt'
import NonStorageTranslationText from '../nonstorage/NonStorageTranslationText'

import ViewPage from './ViewPage'
import TablePage from './TablePage'



//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = NonStorageTranslationText();
      const moduleTitle = translatedTextsObject.moduleTitle;
      return moduleTitle;
}

function WrappedTablePage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <ViewPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}






export {
      WrappedTablePage as AdminTable,
      WrappedViewPage as AdminViewPage,
}