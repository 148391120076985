import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './WarehouseLocationEditFormProperties'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import { Chip } from '@material-ui/core'
import WarehouseTranslationText from './WarehouseTranslationText'
import SCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'
const { table: { CngServerModeDialogFormTable } } = components

function WarehouseStorageTable({ showNotification, id, isViewOnly }) {
	const translatedTextObject = WarehouseTranslationText()
	let create = {
		url: SCOInvMgmtApiUrls.ADD_STORAGE_LOCATION
	}
	let update = { url: SCOInvMgmtApiUrls.UPDATE_STORAGE_LOCATION }
	let del = { url: SCOInvMgmtApiUrls.DELETE_STORAGE_LOCATION }
	const [isEdit, setIsEdit] = useState(false)
	const [isAdd, setIsAdd] = useState(false)
	console.log('isViewOnly ' + isViewOnly)

	if (isViewOnly) {
		create = undefined
		update = undefined
		del = undefined
	}


	const onAddButtonClick = () => {
		setIsEdit(false)
		setIsAdd(true)
	}

	const onEditButtonClick = () => {
		setIsEdit(true)
		setIsAdd(false)
	}

	const onViewButtonClick = () => {
		setIsEdit(false)
		setIsAdd(false)
	}



	const columns = [
		{
			field: "locReferenceId",
			title: translatedTextObject.locationRefId,
			filtering: false,
			sorting: false
		},
		{
			field: "displayLocationName",
			title: translatedTextObject.locationName
		},
		{
			field: "parentLocationName",
			title: translatedTextObject.parentLocation
		},
		{
			field: "locationTagId",
			title: translatedTextObject.locationTags,
			filtering: false,
			sorting: false,
			render: (rowData) => {
				if (rowData !== undefined || rowData !== null) {
					let locTags = rowData.locationTagsDisplay;
					if (locTags !== undefined) {
						return locTags.map((x) => {
							return <Chip key={x.tag.id} label={x.tag.tagTitle} />
						})
					}
				}
			}
		}
	];


	return (
		<CngServerModeDialogFormTable
			showNotification={showNotification}
			disabled={isViewOnly}
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			fetch={{
				url: SCOInvMgmtApiUrls.GET_LOCATION_BY_WAREHOUSE_ID,
				body: {
					'warehouseId': id
				}
			}} //to be updated
			idAccessor='locationId'
			tableColumns={columns}

			// form properties
			formProperties={{
				...FormProperties,
				formikProps: {
					...FormProperties.formikProps,
					initialValues: {
						...FormProperties.formikProps.initialValues,
						id: id
					}
				},
				customFieldsProps: { id: id, isViewOnly: isViewOnly, isEdit: isEdit, isAdd: isAdd }
			}}

			onAddButtonClick={onAddButtonClick}
			onEditButtonClick={onEditButtonClick}
			onViewButtonClick={onViewButtonClick}
			toClientDataFormat={(serverData) => {
				return serverData
			}}
			toServerDataFormat={(localDatum) => {
				return localDatum
			}}
			// form actions
			create={create}
			update={update}
			del={del}

		/>
	);
}

export default WarehouseStorageTable