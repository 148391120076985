import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'

const NonStorageTranslationText = () => {
      const { translate } = useTranslation(Namespace.SCO_INV_MGMT)
      const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
      const locationName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_NAME)
      const locationCode = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_CODE)
      const locationDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_DETAILS)
      const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)


      return {
            moduleTitle,
            locationName,
            locationCode,
            locationDetails,
            partyName
      }
}

export default NonStorageTranslationText