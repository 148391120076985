import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { makeValidationSchema } from './Warehouse_ValidationSchema'
import { useFormContext } from "react-hook-form"
import WarehouseTranslationText from './WarehouseTranslationText'
import { addressDefaultValues } from '../common/makeAddressValidationSchema'

import AddressComponent from 'src/views/sco-invmgmt/common/AddressComponent'

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField, CngSelectField },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    warehouseName: "",
    warehouseTags: [],
    locReferenceId: "",
    partyId: "",
    address: addressDefaultValues
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const { getValues } = useFormContext();
    const values = getValues();
    const isAdmin = props.isAdmin
    const fieldsTranslatedTextObject = WarehouseTranslationText()
    const id = props.id

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.warehouseDetailsTitle} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={4} sm={4} shouldHide={!isAdmin}>
                                        <CngSelectField
                                            disabled={true}
                                            name="partyId"
                                            label={fieldsTranslatedTextObject.partyName}
                                            fetch={
                                                {
                                                    url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                                                    textAccessor: "name"
                                                }
                                            }
                                        />
                                    </CngGridItem>



                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="warehouseName"
                                            label={fieldsTranslatedTextObject.warehouseName}
                                            required={true}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngLookupAutocompleteField
                                            name="warehouseTags"
                                            disabled={disabled}
                                            label={fieldsTranslatedTextObject.warehouseTags}
                                            multiple={true}
                                            freeSolo
                                            lookupProps={{
                                                url: SCOInvMgmtApiUrls.GET_WAREHOUSE_TAGS,
                                                label: (record) => {
                                                    return `${record.tagTitle}`
                                                },
                                                value: (record) => {
                                                    return `${record.tagTitle}`
                                                },
                                                filters: [
                                                    {
                                                        field: 'systemLocationCode',
                                                        operator: 'EQUAL',
                                                        value: 'WHSE'
                                                    }
                                                ]
                                            }}
                                        />

                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.warehouseAddressTitle} />
                                {values.address &&
                                    <AddressComponent
                                        values={values.address}
                                        disabled={disabled}
                                        isWarehouse={true} />
                                }

                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties