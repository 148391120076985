import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'
import CommonTranslationText from '../common/CommonTranslationText'

const SKUTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const sku = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU)
    const skuNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_NUMBER)
    const skuDesc = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DESC)
    const skuCategory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_CATEGORY)
    const supplier = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER)
    const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)
    const skuDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DETAILS)
    const supplierSku = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER_SKU_NUM)
    const uom = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.UOM)
    const baseUom = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BASE_UOM)
    const quantity = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.QUANTITY)
    const alternateUom = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ALT_UOM)
    const addAlternateUom = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADD_ALT_UOM)
    const quantityToBaseUom = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.QUANTITY_TO_BASE_UOM)
    const attributes = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ATTRIBUTES)
    const attribute = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ATTRIBUTE)
    const value = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.VALUE)
    const selectAttribute = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SELECT_ATTRIBUTE)
    const addAttribute = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ADD_ATTRIBUTE)
    const formError = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.FORM_ERROR)
    const stockMovementHistory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_HISTORY_MOVEMENT)
    const skuStockMovementHistory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_STOCK_HISTORY_MOVEMENT)
    const skuDetailsTab = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DETAILS)
    const clone = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CLONE_BUTTON)

    return {
        moduleTitle,
        sku,
        skuNumber,
        skuDesc,
        skuCategory,
        supplier,
        partyName,
        skuDetails,
        supplierSku,
        uom, 
        baseUom,
        quantity,
        alternateUom,
        addAlternateUom,
        quantityToBaseUom,
        attributes, 
        attribute,
        value,
        selectAttribute,
        addAttribute,
        formError,
        stockMovementHistory,
        skuStockMovementHistory,
        skuDetailsTab,
        clone,
        ...commonText
    }
}

export default SKUTranslationText
