import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React, useState } from 'react'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import AddressTranslationText from './AddressTranslationText'

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,
} = components

const AddressComponent = ({ values, disabled, isWarehouse }) => {
    const [selectedCountry, setSelectedCountry] = useState(values ? values.countryCode : null);
    const fieldsTranslatedTextObject = AddressTranslationText()
    const { setFieldValue } = useFormikContext()

    if (isWarehouse == true) {
        isWarehouse = true
    } else {
        isWarehouse = false
    }


    const editAddressFields = (countryValue) => {
        if (!countryValue) {
            setFieldValue('address.state', '');
            setFieldValue('address.street', '');
            setFieldValue('address.city', '');
            setFieldValue('address.postCode', '');
        } else {
            setFieldValue('address.state', '');
        }
    }

    return (

        < Grid container spacing={2} >
            <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                    name='address.countryCode'
                    label={fieldsTranslatedTextObject.addressCountry}
                    disabled={disabled}
                    required={isWarehouse}
                    onChange={(val) => {
                        setSelectedCountry(val);
                        editAddressFields(val);
                    }}
                    lookupProps={{
                        url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                        label: 'name',
                        value: 'code'
                    }}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                    name='address.state'
                    key={selectedCountry}
                    label={fieldsTranslatedTextObject.addressState}
                    disabled={disabled || !values.countryCode}
                    freeSolo
                    lookupProps={{
                        url: SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
                        label: 'stateName',
                        value: 'stateCode',
                        filters: [
                            {
                                field: 'countryCode',
                                operator: 'EQUAL',
                                value: selectedCountry
                            }
                        ]
                    }}
                    required={isWarehouse}
                />
            </CngGridItem>
            <CngGridItem xs={false} sm={4}></CngGridItem>
            <CngGridItem xs={12} sm={4}>
                <CngTextField name='address.street'
                    label={fieldsTranslatedTextObject.addressStreet}
                    disabled={disabled || !values.countryCode}
                    required={isWarehouse}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
                <CngTextField name='address.city'
                    label={fieldsTranslatedTextObject.addressCity}
                    disabled={disabled || !values.countryCode}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
                <CngTextField name='address.postCode'
                    label={fieldsTranslatedTextObject.addressPostCode}
                    disabled={disabled || !values.countryCode}
                />
            </CngGridItem>
        </Grid >

    );
}

export default AddressComponent