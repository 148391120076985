import { Card, CardContent, Grid, Box, makeStyles, Table, TableBody, TableCell, TableRow,} from '@material-ui/core'
import { components, constants, DataFlattener, } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { default as React} from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockTranslationText from '../stock/StockTranslationText'

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    skuId: '',
    wacDU: 0,
    currentValueDU: 0,
    wacDP: 0,
    currentValueDP: 0,
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}
const {

    filter: { EQUAL }
} = constants


const customStyles = makeStyles(theme => ({
    chip: {
          color: theme.palette.primary.main,
          borderColor: theme.palette.divider,
          fontSize: '.75em',
          minWidth: '5em'
    },
    text: {
          alignContent: 'right',
          fontWeight: 'bold',
          fontSize: '.95em'
    },
    editor: {
          border: '1px solid #EEEEEE  !important',
          padding: '2px 12px',
          borderRadius: '0 0 4px 4px',
          marginTop: '-6px'
    },
    editorTitle: {
          padding: '10px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          border: '1px solid #EEEEEE',
          borderBottom: 0
    },
    checkboxCustom: {
          border: '1px #EEEEEE solid !important',
          padding: '10px 14px',
          borderRadius: '4px',
          height: '58px'
    },
    flags: {
          height: '1.2em', width: '1.2em',
          display: 'inline-block'
    },
    label: {
          overflowWrap: 'anywhere'
    },
    details: {
        display: 'table',
        fontSize: '.9em',
        marginTop: theme.spacing(1.5),
        '& td': {
          padding: theme.spacing(1.25, 2),
          '&:nth-child': {
            '&(1)': { borderRadius: '14px 0 0 14px' },
            '&(2)': { color: theme.palette.text.textSecondary },
            '&(3)': { borderRadius: '0 14px 14px 0' }
          }
    
        }
      },
    lightGreyText: {
        color: '#8996AF'
    }

}))




//* Fields function ------------------------------------------------------------
function Fields({  disabled, skuId, }) {
    const fieldsTranslatedTextObject = StockTranslationText();
    const styleClasses = customStyles();
    const { setFieldValue } = useFormikContext()
    const {getValues} = useFormContext()

    setFieldValue('skuId', skuId)
    if(skuId!=null){
        console.log("Set skuId")
    }

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent 
                                        title={fieldsTranslatedTextObject.weightedCost}
                                    hideMandatory />
                                </Box>

                                <Grid container spacing={2}>
                                    <Table size='small'  
                                        className={styleClasses.details}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell> {fieldsTranslatedTextObject.wacDU} </TableCell>
                                                <TableCell className={styleClasses.lightGreyText}> 
                                                    {fieldsTranslatedTextObject.wacDUCal}
                                                </TableCell> 
                                                <TableCell align='right'> <b>{getValues("wacDU")} </b> </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell> {fieldsTranslatedTextObject.currentValueDU} </TableCell>
                                                <TableCell  className={styleClasses.lightGreyText}>
                                                    {fieldsTranslatedTextObject.currentValueDUCal}
                                                </TableCell>
                                                <TableCell align='right'> <b>{getValues("currentValueDU")}</b> </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>{fieldsTranslatedTextObject.wacDP}</TableCell>
                                                <TableCell  className={styleClasses.lightGreyText}>
                                                    {fieldsTranslatedTextObject.wacDPCal}
                                                </TableCell>
                                                <TableCell align='right'> <b>{getValues("wacDP")}</b> </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell> {fieldsTranslatedTextObject.currentValueDP} </TableCell>
                                                <TableCell  className={styleClasses.lightGreyText}>
                                                    {fieldsTranslatedTextObject.currentValueDPCal}
                                                </TableCell>
                                                <TableCell align='right'> <b>{getValues("currentValueDP")}</b> </TableCell>
                                            </TableRow>
                                        </TableBody>
                                
                                    </Table>
                
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    console.log("Inside toClientDataFormat :::",JSON.stringify(serverData));
    return DataFlattener.parse(serverData);
}
    
function toServerDataFormat(localData) {
    console.log("Inside toServerDataFormat ::: ",JSON.stringify(localData));
    return DataFlattener.unflatten(localData);
}

const Weight_Cost_FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default Weight_Cost_FormProperties


