import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_SCOInvMgmt'
import SKUTranslationText from './SKUTranslationText'
import TablePage from './TablePage'
import ViewPage from './ViewPage'
import EditPage from './EditPage'
import CreatePage from './CreatePage.js'
import AdminTablePage from './AdminTablePage.js'
import AdminViewPage from './AdminViewPage'
import UploadSkuPage from './upload/UploadSkuPage'
import ConfirmSkuDetailsPage from './upload/ConfirmSkuDetailsPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = SKUTranslationText();
      return translatedTextsObject.moduleTitle;
}

function WrappedTablePage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedViewPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <ViewPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


function WrappedAddPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <CreatePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedEditPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <EditPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedAdminTablePage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <AdminTablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedAdminViewPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <AdminViewPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedUploadSkuPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <UploadSkuPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedConfirmSkuDetailsPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <ConfirmSkuDetailsPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

export {
      WrappedTablePage as TablePage,
      WrappedViewPage as ViewPage,
      WrappedAddPage as AddPage,
      WrappedEditPage as EditPage,
      WrappedAdminTablePage as AdminTablePage,
      WrappedAdminViewPage as AdminViewPage,
      WrappedUploadSkuPage as UploadSkuPage,
      WrappedConfirmSkuDetailsPage as ConfirmSkuDetailsPage
}