import { Card, CardContent, Grid} from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'

import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components


function TablePage(props) {
      const {
            location: { pathname },
            showNotification,
      } = props
      const translatedTextObject = PrincipalMappingTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: "id",
                  title: translatedTextObject.partyName,
                  sorting: true,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "partyName",
                  title: translatedTextObject.partyName,
                  hidden: true
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <Grid item xs={12}>
                                    <CngCrudTable
                                          {...props}
                                          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                          columns={columns}
                                          editRoute={`${pathname}/edit`}
                                          fetch={{ url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get` }}
                                          idAccessor="id"
                                          notification={notification}
                                    />
                              </Grid>
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage