import { components } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import PrincipalMapping_FormProperties from './PrincipalMapping_FormProperties'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'

const {
      table: {
            useFetchCustomLookup,
            CngServerModeDialogFormTable
      }
} = components


function PrincipalMappingTable(props) {
      const showNotification = props.showNotification
      const fetchCustomLookup = useFetchCustomLookup();
      const fieldsTranslatedTextObject = PrincipalMappingTranslationText();
      const partyId = props.partyId;
      console.log('this is partyId ' + partyId)

      const columns = [
            {
                  field: 'accountCompanyId',
                  title: fieldsTranslatedTextObject.principalCompanyId,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${CecAccountApiUrls.GET_ACCOUNT}`,
                              {
                                    partyId: partyId,
                                    status: 'actv'
                              },
                              'content',
                              'displayName', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  }

            },
            {
                  field: 'accountPartyId',
                  title: fieldsTranslatedTextObject.principalPartyId,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            }
      ]

      return (
            <CngServerModeDialogFormTable
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  disabled={false}
                  showNotification={showNotification}
                  tableColumns={columns}
                  formProperties={{
                        ...PrincipalMapping_FormProperties,
                        formikProps: {
                              ...PrincipalMapping_FormProperties.formikProps,
                              initialValues: {
                                    ...PrincipalMapping_FormProperties.formikProps.initialValues,
                                    partyId: props.partyId
                              }
                        },
                        customFieldsProps: { partyId: props.partyId }
                  }}
                  toClientDataFormat={(serverData) => {
                        return serverData;
                  }}
                  toServerDataFormat={(localDatum) => {
                        return localDatum;
                  }}
                  fetch={{
                        url: SCOInvMgmtApiUrls.GET_PRINCIPAL_MAPPING_BY_PARTYID,
                        body: {
                              partyId: partyId
                        }
                  }}
                  create={{ url: SCOInvMgmtApiUrls.CREATE_PRINCIPAL_MAPPING }}
                  update={{ url: SCOInvMgmtApiUrls.UPDATE_PRINCIPAL_MAPPING }}
                  del={{ url: SCOInvMgmtApiUrls.DELETE_PRINCIPAL_MAPPING }}
                  idAccessor='id'
            />
      )
}

export default PrincipalMappingTable