import { Card, CardContent, Grid, Box, Chip, makeStyles, Table, TableBody, TableCell, TableRow,} from '@material-ui/core'
import { components, constants, DataFlattener, } from 'cng-web-lib'
import { useWatch, useFormContext } from 'react-hook-form'
import { default as React} from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockTranslationText from './StockTranslationText'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import { ChipOption, ChipOptionWithEnd } from 'src/views/sco-ratemgmt/contract/add-page/options'

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    stockId: '',
    currencyCode: 'USD',
    productCost: 0,
    freightCost: 0,
    insuranceCost: 0,
    importHandlingCost: 0,
    overheadCost: 0,
    customDuty: 0,
    exciseDuty: 0,
    taxCode: 'SG-GST',
    totalLandedCost: 0,
    totalLandedCostNet: 0,
    currencyCodeForDisplay: 'USD',
    
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}
const {

    filter: { EQUAL }
} = constants


const customStyles = makeStyles(theme => ({
    chip: {
          color: theme.palette.primary.main,
          borderColor: theme.palette.divider,
          fontSize: '.75em',
          minWidth: '5em'
    },
    text: {
          alignContent: 'right',
          fontWeight: 'bold',
          fontSize: '.95em'
    },
    editor: {
          border: '1px solid #EEEEEE  !important',
          padding: '2px 12px',
          borderRadius: '0 0 4px 4px',
          marginTop: '-6px'
    },
    editorTitle: {
          padding: '10px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          border: '1px solid #EEEEEE',
          borderBottom: 0
    },
    checkboxCustom: {
          border: '1px #EEEEEE solid !important',
          padding: '10px 14px',
          borderRadius: '4px',
          height: '58px'
    },
    flags: {
          height: '1.2em', width: '1.2em',
          display: 'inline-block'
    },
    label: {
          overflowWrap: 'anywhere'
    },
    details: {
        display: 'table',
        fontSize: '.9em',
        marginTop: theme.spacing(1.5),
        '& td': {
          padding: theme.spacing(1.25, 2),
          '&:nth-child': {
            '&(1)': { borderRadius: '14px 0 0 14px' },
            '&(2)': { color: theme.palette.text.textSecondary },
            '&(3)': { borderRadius: '0 14px 14px 0' }
          }
    
        }
      },
    lightGreyText: {
        color: '#8996AF'
    }

}))




//* Fields function ------------------------------------------------------------
function Fields({  disabled, stockId, }) {
    const fieldsTranslatedTextObject = StockTranslationText();
    const styleClasses = customStyles();
    const selectedCurrency = useWatch({ name: `currencyCode` })
    const selectedTax = useWatch({ name: `taxCode` })
    const { setFieldValue } = useFormikContext()
    const {getValues} = useFormContext()

    setFieldValue('stockId', stockId)
    if(stockId!=null){
        console.log("Set StockId")
    }

    const getChipForCurrency = code => (
        <Box>
            <div>
                <Chip label={<b>{code}</b>} size='small' />
            </div>
        </Box>
      )
    
    function getLabelForCurrency(symbol, endAdornment) {
        return (
            <div>
                <Box className={styleClasses.text} color='text.secondary'>
                    {symbol}
                </Box>
                {endAdornment}
            </div>
        )
    }

    const getChipForTax = taxPercent =>  (
        <Box>
            <div>
                <Chip label={<b>{(taxPercent * 100).toFixed(0) + '%'}</b>} size='small' />
            </div>
        </Box>
    )
    

    
    const currencyTextDropDown ={
        customStartAdornment: (startAdornment, options) => {
            const matched = options.map((option) => option.data).find((currency) => currency?.code === selectedCurrency)
            if (matched && selectedCurrency) {
                return getChipForCurrency(matched.code)
            }
                return startAdornment
        },
        customEndAdornment: (endAdornment, options) => {
            const matched = options.map((option) => option.data).find((currency) => currency?.code === selectedCurrency)
            if (matched && selectedCurrency) {
                return getLabelForCurrency(matched.symbol, endAdornment)
            }
            return endAdornment
        }
    }

    const taxTextDropDown ={
        customStartAdornment: (startAdornment, options) => {
            const matched = options.map((option) => option.data).find((tax) => tax?.code === selectedTax)
            if (matched && selectedTax) {
                return getChipForTax(matched.taxPercent)
            }
            return startAdornment
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.purchasingInformation} hideMandatory />
                                </Box>
                                <Grid container spacing={2}>

                                    <CngGridItem xs={12} sm={4}>
                                        <CngLookupAutocompleteField
                                            disabled={disabled}
                                            key={`stockId + currencyCode`}
                                            name={`currencyCode`}
                                            label={fieldsTranslatedTextObject.currency}
                                            required
                                            lookupProps={{
                                                url: SCORateMgmtApiUrls.GET_CURRENCY,
                                                label: 'name',
                                                value: 'code',
                                                 filters: [
                                                        {
                                                            field: 'status',
                                                            operator: EQUAL,
                                                            value: true
                                                    }
                                                ]
                                            }}
                                            renderOption={(option) => (
                                                <ChipOptionWithEnd id={option.data.id} chip={option.data.code} text={option.data.name} end={option.data.symbol} />
                                            )}
                                            onChange={(e, ) => { 
                                                console.log('this is currencyCode e ::' + e)
                                                setFieldValue('currencyCode', e)
                                            }}
                                            textFieldProps={{
                                                InputProps: currencyTextDropDown
                                            }}
                                        />
                                    </CngGridItem>                               

                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="productCost"
                                            label={fieldsTranslatedTextObject.productCost}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="insuranceCost"
                                            label={fieldsTranslatedTextObject.insuranceCost}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="freightCost"
                                            label={fieldsTranslatedTextObject.freightCost}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="importHandlingCost"
                                            label={fieldsTranslatedTextObject.importHandlingCost}
                                        />
                                    </CngGridItem>
                                    
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="overheadCost"
                                            label={fieldsTranslatedTextObject.overheadCost}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="customDuty"
                                            label={fieldsTranslatedTextObject.customDuty}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="exciseDuty"
                                            label={fieldsTranslatedTextObject.exciseDuty}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4}>
                                        <CngLookupAutocompleteField
                                            disabled={disabled}
                                            key={`stockId + taxCode`}
                                            name={`taxCode`}
                                            label={fieldsTranslatedTextObject.taxCode}
                                            // required
                                            lookupProps={{
                                                url: SCORateMgmtApiUrls.GET_TAX,
                                                label: (record) => {
                                                    return `${record.countryCode + ' - ' + record.description}`
                                                },
                                                value: 'code'
                                            }}
                                            renderOption={(option) => (
                                                 <ChipOption id={option.data.id} chip={(option.data.taxPercent * 100).toFixed(0) + '%'} text={option.data.countryCode + ' - ' + option.data.description} />
                                            )}
                                            //THis to save the db
                                            onChange={(e, ) => { 
                                                console.log('this is taxCode e ::' + e)
                                                setFieldValue('taxCode', e)
                                            }}
                                            textFieldProps={{
                                                InputProps: taxTextDropDown
                                            }}
                                        />
                                    </CngGridItem>

                                </Grid>
                            </Grid>
                        </CardContent>
                        <br/>

                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.totalLandedCost} hideMandatory />
                                </Box>

                                <Grid container spacing={2}>
                                    <Table size='small'  
                                        className={styleClasses.details}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell> {fieldsTranslatedTextObject.excludingDutiesAndTaxes} </TableCell>
                                                <TableCell className={styleClasses.lightGreyText}> {fieldsTranslatedTextObject.excludingDutiesAndTaxesDesc} </TableCell> 
                                                <TableCell align='right'> <b>{getValues("totalLandedCost")} {getValues("currencyCodeForDisplay")}</b> </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell> {fieldsTranslatedTextObject.withDuties} </TableCell>
                                                <TableCell  className={styleClasses.lightGreyText}>{fieldsTranslatedTextObject.withDutiesDesc}</TableCell>
                                                <TableCell align='right'> <b>{getValues("totalLandedCostNet")} {getValues("currencyCodeForDisplay")}</b> </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    
                                    </Table>
                

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    console.log("Inside toClientDataFormat :::",JSON.stringify(serverData));
    return DataFlattener.parse(serverData);
}
    
function toServerDataFormat(localData) {
    console.log("Inside toServerDataFormat ::: ",JSON.stringify(localData));
    return DataFlattener.unflatten(localData);
}

const CostDetails_FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default CostDetails_FormProperties


