import { Box, Button, CircularProgress, Grid, Paper } from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import { Download } from 'react-feather'
import { useFormContext } from 'react-hook-form'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import * as Yup from 'yup'

const {
    dropzone: { CngFileUpload, HelperTextArea },
    CngGridItem,
    form: {
        adapter: {
            useFormAdapter: { useField }
        },
        CngForm
    },
    button: { CngPrimaryButton }
} = components
const { FormState, NotificationType } = constants

function UploadSkuPage(props) {
    const [shouldShowPrimaryButtonCircularProgress, setShouldShowPrimaryButtonCircularProgress] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([]);

    const { showNotification, history } = props

    const validationSchema = Yup.object({})
    const { createRecord } = useServices();

    function onSubmit(data) {
        setShouldShowPrimaryButtonCircularProgress(true)

        const onSuccess = (response) => {
            setShouldShowPrimaryButtonCircularProgress(false)
            if (response.errorMessages != null && response.errorMessages.length > 0) {
                showNotification(NotificationType.ERROR, "An error occurred, please fix the errors and re-upload.")
                setErrorMsgs(response.errorMessages);
            } else {
                history.push({
                    pathname: pathMap.SCO_INV_SKU_CONFIRM_UPLOAD_DETAILS,
                    state: {
                        response: response,
                    }
                })
            }
        }
        const onError = () => {
            setShouldShowPrimaryButtonCircularProgress(false)
            showNotification(NotificationType.ERROR, "Something went wrong.")
        }

        function onComplete(resolve) {
            setShouldShowPrimaryButtonCircularProgress(false)
            resolve()
        }

        return new Promise((resolve) => {
            if (data && data.files.length > 0) {
                var formData = new FormData()
                formData.append('file', data.files[0].file)
                createRecord.execute(
                    SCOInvMgmtApiUrls.UPLOAD_SKU_DOC,
                    formData,
                    onSuccess,
                    onError,
                    () => onComplete(resolve)
                )
            } else {
                showNotification(NotificationType.ERROR, 'Please upload a file')
                resolve()
            }
        })
    }

    return (
        <Paper>
            <CngForm
                formikProps={{
                    initialValues: { files: [] },
                    validationSchema: validationSchema,
                    onSubmit: onSubmit

                }}
                renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                    <>
                        <Fields
                            labelMap={labelMap}
                            shouldHideMap={shouldHideMap}
                            shouldDisableMap={shouldDisableMap}
                            showNotification={showNotification}
                            errorMsgs={errorMsgs}
                            setErrorMsgs={setErrorMsgs}
                        />
                    </>
                )}
                renderButtonSection={() => <></>}
                renderButtonSection={() => (
                    <SubmitButton
                        shouldShowPrimaryButtonCircularProgress={shouldShowPrimaryButtonCircularProgress}
                        setShouldShowPrimaryButtonCircularProgress={setShouldShowPrimaryButtonCircularProgress}
                        showNotification={showNotification}
                        onSubmit={onSubmit}
                    />
                )}
                formState={FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            />
        </Paper>
    )
}

const SubmitButton = ({ shouldShowPrimaryButtonCircularProgress, onSubmit }) => {

    const {
        formState: { isSubmitting },
        handleSubmit
    } = useFormContext()


    return (
        <Box p={5}>
            <CngPrimaryButton
                type='submit'
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                endIcon={
                    shouldShowPrimaryButtonCircularProgress ? (
                        <CircularProgress color='inherit' size={10} />
                    ) : null
                }
            >
                Upload
            </CngPrimaryButton>

        </Box>
    );
}

function Fields({
    showNotification,
    errorMsgs,
    setErrorMsgs
}) {
    const [uploadFile, , { setValue: setUploadFile }] = useField('files')
    const moreActions = [
        {
            action: 'remove',
            name: 'Remove',
            icon: ['fal', 'trash']
        }
    ]

    useEffect(() => {
        if (uploadFile.value.length == 0 && errorMsgs.length > 0) {
            setErrorMsgs([])
        }
    }, [uploadFile])

    return (
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={12}>
                <Box style={{ float: 'right' }} pr={5} pt={2} >
                    <a
                        className='download'
                        href={
                            process.env.PUBLIC_URL +
                            '/static/doc/SKU_UPLOAD_TEMPLATE.xlsx'
                        }
                        download={'SKU_UPLOAD_TEMPLATE.xlsx'}
                    >
                        <Button
                            variant='contained'
                            color='secondary'
                            startIcon={<Download style={{ marginRight: 5 }} />}
                            classes={{ root: 'ng-button-filled-secondary' }}
                        >

                            {"Download Template"}
                        </Button>
                    </a>
                </Box>
            </CngGridItem>
            <CngGridItem xs={12} sm={12}>
                <Box pr={5} pl={5}>
                    <Box alignItems='center' pt={1}>
                        <CngFileUpload
                            accept={['.xls', '.xlsx']}
                            maxFiles={1}
                            maxSize={5242880}
                            onFileSelect={(files) => {
                                setUploadFile(files)
                            }}
                            files={uploadFile.value}
                            showFormFields={false}
                            moreActions={moreActions}
                            onDropRejected={(errorMessages) => {
                                showNotification(NotificationType.ERROR, errorMessages[0])
                            }}
                            renderHelperText={() => {
                                return (
                                  <HelperTextArea
                                  accept={['.xls', '.xlsx']}
                                    maxFiles={1}
                                    maxSize={5242880}
                                  />
                                )
                            }}
                        />
                    </Box>
                </Box>
            </CngGridItem>
            {errorMsgs && errorMsgs.length > 0 &&
                <CngGridItem xs={12} sm={12}>
                    <Box pr={5} pl={5}>
                        <Box alignItems='center'>
                            {errorMsgs.map((msg) => (

                                <p style={{ color: '#FF808B' }}>
                                    {msg}
                                </p>
                            ))}
                        </Box>
                    </Box>
                </CngGridItem>
            }
        </Grid>
    )
}

export default UploadSkuPage
