import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'
import CommonTranslationText from '../common/CommonTranslationText'

const PrincipalMappingTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)
    const partyId = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_ID)
    const mappedPrincipals = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MAPPED_PRINCIPALS)
    const partyDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_DETAILS)
    const principalCompanyId = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRINCIPAL_COMPANY_ID)
    const principalPartyId = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRINCIPAL_PARTY_ID)
    const principalMappingForm = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRINCIPAL_MAPPING_FORM)
    const operator = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.OPERATOR)
    const skuNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_NUMBER)
    const skuDesc = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DESC)
    const lotNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOT_NUMBER)
    const quantity = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.QUANTITY)
    const mfgDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MANUFACTURING_DATE)
    const expDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXPIRY_DATE)
    const updatedDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.UPDATED_DATE)
    const stockDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_DETAILS)
    const stockHistory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_HISTORY)
    const principal = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRINCIPAL)
    const skuDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DETAILS)
    const skuCateogry = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_CATEGORY)
    const supplierSkuNum = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER_SKU_NUM)
    const supplier = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER)
    const stockAllocations = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_ALLOCATIONS)
    const location = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION)
    const dutyStatus = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUTY_STATUS)
    const permitType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PERMIT_TYPE)
    const permitNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PERMIT_NUMBER)
    const buyer = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BUYER)
    const updatedDatetime = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.UPDATED_DATETIME)
    const operationType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.OPERATION_TYPE)
    const origin = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ORIGIN)
    const destination = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DESTINATION)
    const movementType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MOVEMENT_TYPE)
    const fromLocation = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.FROM_LOCATION)
    const toLocation = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.TO_LOCATION)



    return {
        moduleTitle,
        partyName,
        partyId,
        mappedPrincipals,
        partyDetails,
        principalCompanyId,
        principalPartyId,
        principalMappingForm,
        operator,
        skuNumber,
        skuDesc,
        lotNumber,
        quantity,
        mfgDate,
        expDate,
        updatedDate,
        stockDetails,
        stockHistory,
        principal,
        skuDetails,
        skuCateogry,
        supplierSkuNum,
        supplier,
        stockAllocations,
        location,
        dutyStatus,
        permitType,
        permitNumber,
        buyer,
        updatedDatetime,
        operationType,
        origin,
        destination,
        movementType,
        fromLocation,
        toLocation,
        ...commonText
    }
}

export default PrincipalMappingTranslationText
