import {
    components
} from 'cng-web-lib';
import React, { useState } from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import FormProperties from './Warehouse_FormProperties';
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'

const {
    table: {
        useDefaultNotification,
    }
} = components


const { form: { CngAddForm } } = components

function CreatePage({ history, showNotification }) {
    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            buttonText="Create"
            create={{
                url: SCOInvMgmtApiUrls.CREATE_WAREHOUSE,
                successRedirect: InvSCOPathMap.SCO_INV_WAREHOUSE_LIST
            }}
        />
    )

}

export default CreatePage
