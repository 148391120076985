import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import { useFormContext } from "react-hook-form"
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import AddressComponent from 'src/views/sco-invmgmt/common/AddressComponent'
import { addressDefaultValues } from '../common/makeAddressValidationSchema'
import SupplierTranslationText from './SupplierTranslationText'
import { makeValidationSchema } from './Supplier_ValidationSchema'

const {
    form: {
        field: { CngTextField, CngSelectField },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    supplierName: "",
    partyId: "",
    address: addressDefaultValues
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, isAdmin } = props;
    const fieldsTranslatedTextObject = SupplierTranslationText()
    const { getValues } = useFormContext();
    const values = getValues();

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.supplierDetails} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4} shouldHide={!isAdmin}>
                                        <CngSelectField
                                            disabled={true}
                                            name="partyId"
                                            label={fieldsTranslatedTextObject.partyName}
                                            fetch={
                                                {
                                                    url: SCOInvMgmtApiUrls.GET_PARTY,
                                                    textAccessor: "name"
                                                }
                                            }
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="supplierName"
                                            label={`${fieldsTranslatedTextObject.supplierName}`}
                                            required
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.supplierAddress} />
                                {values.address &&
                                    <AddressComponent
                                        values={values.address}
                                        disabled={disabled} />
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
