import {Card, CardContent, Grid, } from '@material-ui/core'
import { components, constants,useServices } from 'cng-web-lib'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import StockTranslationText from './StockTranslationText'
import FormProperties from './Stock_FormProperties'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockHistoryTable from './StockHistoryTable'
import CostDetails_FormProperties from './Stock_Cost_Details_FormProperties'
import CostDetailsSchema from './validatioNSchemas/costDetailsSchema'
import ButtonComponents from './components/ButtonComponents'

const { 
	form: { 
		CngViewForm, CngEditForm,
	},
	table: { useDefaultNotification },
	button: { CngSecondaryButton, },
	CngTabs,
} = components


const { FormState, NotificationType } = constants

function EditPage({ history, showNotification, props}) {
	const { id } = useParams()
	const translatedTextObject = StockTranslationText()
	const validationSchema = CostDetailsSchema()
	const [shouldShowPrimaryButtonCircularProgress, setShouldShowPrimaryButtonCircularProgress] = useState(false);

	const {
		success: showSuccessNotification,
		error: showErrorNotification
  	} = useDefaultNotification(showNotification)

	const { updateRecord } = useServices()

	function onSubmit(submittedData) {
		console.log("Check for submittedData :::", JSON.stringify(submittedData));

		setShouldShowPrimaryButtonCircularProgress(true)
		function onSuccess(response) {
			setShouldShowPrimaryButtonCircularProgress(false)

			if (response.errorMessages != null && response.errorMessages.length > 0){
				console.log("On Submit ::: return error message");
				showErrorNotification(response.errorMessages)
				
			}
			else{
				console.log("On Submit ::: Success");
				showSuccessNotification("Cost Details updated successfully​")
			}

		}

		function onError(error) {
			setShouldShowPrimaryButtonCircularProgress(false)
			console.log("Error::::", JSON.stringify(error))
			showErrorNotification("Update Records has failed. (" + error.message + ")")
		}

		updateRecord.execute(
			SCOInvMgmtApiUrls.UPDATE_STOCK_COST_DETAILS_BY_ID,
			{
				...submittedData,
				stockId: id,
			},
			onSuccess,
			onError
		)
	}

	return (
        <Grid container spacing={3}>
			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					activeTabIndex ={2} //This is to set the tab as primary 
					tabs={[
						{
							tabName: translatedTextObject.stockDetails,
							tabContent: (
								<CngViewForm
                        			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        			showNotification={showNotification}
                        			bodySection={
                        				<>
                        					<FormProperties.Fields
                        						disabled={true}
                        						showNotification={showNotification}
                        						id={id}
                        					/>

                        					<Grid container spacing={3}>
                        						<Grid item>
                        							<CngSecondaryButton
                        								onClick={() => { history.push(pathMap.SCO_INV_STOCK_INVENTORY_LIST) }}>
                        								{translatedTextObject.back}
                        							</CngSecondaryButton>
                        						</Grid>
                        					</Grid>
                        				</>
                        			}
                        			formikProps={FormProperties.formikProps}
									fetch={{
										id: id,
										url: `${SCOInvMgmtApiUrls.GET_STOCK_BY_ID}`
									}}

                        		/>
							)
						},
						{
							tabName: translatedTextObject.stockHistory,
							tabContent: (
								<CngViewForm
                        			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        			showNotification={showNotification}
                        			bodySection={
                        				<>
											<Card>
												<CardContent>
													<Grid container>
														<AccordionHeaderComponent title={translatedTextObject.stockHistory} hideMandatory />
														<StockHistoryTable
										    				showNotification={showNotification}
										    				id={id}
										    				isViewOnly={true}
														/>
													</Grid>
												</CardContent>
											</Card>
											<CardContent >
											   
											</CardContent>
											<Grid container spacing={3}>
                        						<Grid item>
                        							<CngSecondaryButton
                        								onClick={() => { history.push(pathMap.SCO_INV_STOCK_INVENTORY_LIST) }}>
                        								{translatedTextObject.back}
                        							</CngSecondaryButton>
                        						</Grid>
                        					</Grid>
                        				</>
                        			} //end of bodySection
                        			formikProps={FormProperties.formikProps}
									fetch={{
										id: id,
										url: `${SCOInvMgmtApiUrls.GET_STOCK_BY_ID}`
									}}
                        		/>
							)
						},
						{
							tabName: translatedTextObject.costDetails,
							tabContent: (
								<CngEditForm
                        			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        			showNotification={showNotification}
                        			bodySection={
                        				<>
                        					<CostDetails_FormProperties.Fields
                        						disabled={false}
                        						stockId={id}
                        					/>
                        				</>
                        			}
									renderButtonSection={() => (
										<>
											<ButtonComponents 
												history = {history}
												shouldShowPrimaryButtonCircularProgress= {shouldShowPrimaryButtonCircularProgress}
												setShouldShowPrimaryButtonCircularProgress={setShouldShowPrimaryButtonCircularProgress}
											/>
										</>
									)}
									toClientDataFormat={CostDetails_FormProperties.toClientDataFormat}
          							toServerDataFormat={CostDetails_FormProperties.toServerDataFormat}
                        			formikProps={{
										...CostDetails_FormProperties.formikProps, 
										validationSchema,
										onSubmit: onSubmit
									}}
									id={id}
									fetch={{
										id: id,
										url: `${SCOInvMgmtApiUrls.GET_STOCK_COST_DETAILS_BY_STOCK_ID}`
									}}

                        		/>
							)
						},
					]}
				/>
			</Grid>

		</Grid>

	)

}


export default EditPage
