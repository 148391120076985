import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { makeAddressValidationSchema } from '../common/makeAddressValidationSchema'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const FIELDS_MAX_LENGTH = {
	SUPPLIER_NAME: 255
}

export function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)

	return Yup.object({
		supplierName: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.SUPPLIER_NAME, exceedCharMsg),
		address: makeAddressValidationSchema(translate)		
	})
}

