import { Box, Button, Card, CardContent, Grid, IconButton } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import { useFieldArray } from "react-hook-form"
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import CategoryTranslationText from './CategoryTranslationText'
import { makeValidationSchema } from './Category_ValidationSchema'

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField, CngSelectField },
        adapter: {
            useFormAdapter: { useFormikContext }
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    categoryName: "",
    partyId: "",
    attributeConfigObjList: []
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}
const INPUT_CODE_TYPE = 'COM_INPUT_TYPE'

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, isViewOnly, isAdmin, isEdit } = props;
    const fieldsTranslatedTextObject = CategoryTranslationText()
    const { fields: attributeConfigObjList, remove: removeAttrConfig, append: appendAttrConfig } = useFieldArray({ name: "attributeConfigObjList", keyName: 'key' });

    const handleAddAttribute = () => {
        const attributeItem = {
            attributeName: "",
            inputTypeId: ""
        }
        appendAttrConfig(attributeItem);
    };

    const handleRemoveAttribute = (index) => {
        removeAttrConfig(index)
    };

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.skuCatDetails} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4} shouldHide={!isAdmin}>
                                        <CngSelectField
                                            disabled={true}
                                            name="partyId"
                                            label={fieldsTranslatedTextObject.partyName}
                                            fetch={
                                                {
                                                    url: SCOInvMgmtApiUrls.GET_PARTY,
                                                    textAccessor: "name"
                                                }
                                            }
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="categoryName"
                                            label={`${fieldsTranslatedTextObject.categoryName}`}
                                            required
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <AccordionHeaderComponent title={fieldsTranslatedTextObject.categoryAttributes} />
                            <Grid container spacing={2}>
                                {isViewOnly && attributeConfigObjList.length === 0 &&
                                    <CngGridItem xs={12}>
                                        <p>{fieldsTranslatedTextObject.noAttrMsg} </p>
                                    </CngGridItem>
                                }
                                {attributeConfigObjList.map((config, index) => {
                                    return (
                                        <CngGridItem xs={12} sm={12} key={config.key}>
                                            <Card className="inner-card-group" >
                                                <Grid container spacing={2}>
                                                    <CngGridItem xs={12} sm={4}>
                                                        <CngTextField
                                                            name={`attributeConfigObjList[${index}].attributeName`}
                                                            label={`${fieldsTranslatedTextObject.attributeName}`}
                                                            required
                                                            disabled={disabled}
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} sm={4}>
                                                        <CngLookupAutocompleteField
                                                            name={`attributeConfigObjList[${index}].inputTypeId`}
                                                            label={fieldsTranslatedTextObject.dataType}
                                                            disabled={disabled || (config.id && isEdit)}
                                                            required
                                                            lookupProps={{
                                                                url: `${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/${INPUT_CODE_TYPE}`,
                                                                label: 'name',
                                                                value: 'id'
                                                            }}
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} sm={4}>
                                                        {!isViewOnly &&
                                                            <Box style={{ textAlign: 'right' }}>
                                                                <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveAttribute(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        }
                                                    </CngGridItem>

                                                </Grid>

                                            </Card>
                                        </CngGridItem>

                                    );
                                })}

                            </Grid>
                        </CardContent>
                        {!isViewOnly &&
                            <Button
                                variant="contained"
                                color="default"
                                fullWidth
                                disabled={disabled}
                                startIcon={<AddBoxIcon />}
                                className={'accordion-footer-add-component'}
                                onClick={() => handleAddAttribute()}
                            >
                                <Box style={{ textAlign: 'left' }}>
                                    <Box >
                                        {fieldsTranslatedTextObject.addAttribute}
                                    </Box>

                                </Box>
                            </Button>
                        }
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties