import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import { useTranslation } from 'cng-web-lib'
import CommonTranslationText from '../common/CommonTranslationText'


const DefaultLocConfigTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);

    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const defaultLocations = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DEFAULT_LOCS)
    const movementType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MOVEMENT_TYPE)
    const defaultLocation = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DEFAULT_LOC)
    const locationName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION_NAME)

    return {
        moduleTitle,
        defaultLocations,
        movementType,
        defaultLocation,
        locationName,
        ...commonText
    }
}

export default DefaultLocConfigTranslationText
