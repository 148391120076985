import React from 'react'
import { Card, CardContent, Grid, Chip } from '@material-ui/core'

import {
      components,
      constants
} from 'cng-web-lib'

import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import WarehouseTranslationText from './WarehouseTranslationText'
import { getPartyID } from 'src/store/intelligent-advisory'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants


function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props


      const partyId = getPartyID();
      const translatedTextObject = WarehouseTranslationText()
      const notification = useDefaultNotification(showNotification)


      const columns = [
            {
                  field: "locReferenceId",
                  title: translatedTextObject.locationRefId,
                  filtering: false,
                  sorting: false
            },
            {
                  field: "warehouseName",
                  title: translatedTextObject.warehouseName
            },
            {
                  field: "warehouseTags",
                  title: translatedTextObject.warehouseTags,
                  filtering: false,
                  sorting: false,
                  render: (rowData) => {
                        let whTags = rowData.warehouseTags;
                        return whTags.map((x) => {
                              return <Chip key={x.tag.id} label={x.tag.tagTitle} />
                        })
                  }
            },
            {
                  field: 'createdDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    addRoute={`${pathname}/create`}
                                    fetch={{ url: SCOInvMgmtApiUrls.GET_WAREHOUSE_LIST }}
                                    fetchFilters={[{ field: 'partyId', operator: EQUAL, value: partyId }]}
                                    del={{ url: SCOInvMgmtApiUrls.DELETE_WAREHOUSE }}
                                    editRoute={`${pathname}/edit`}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage