import React from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './Warehouse_FormProperties'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'
import { Grid } from '@material-ui/core'

const {
      form: { CngViewForm },
      button: { CngPrimaryButton, CngSecondaryButton },
} = components


function ViewForm({ history, showNotification, id }) {

      return (
            <CngViewForm
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  history={history}
                  showNotification={showNotification}
                  bodySection={
                        <>
                              <FormProperties.Fields
                                    showNotification={showNotification}
                                    id={id}
                                    disabled={true}
                              />

                              <Grid container spacing={3}>
                                    <Grid item>
                                          <CngPrimaryButton style={{ marginRight: '10px' }}
                                                onClick={() => history.push(`${InvSCOPathMap.SCO_INV_WAREHOUSE_LIST}/edit/${id}`)}>
                                                Edit
                                          </CngPrimaryButton>
                                          <CngSecondaryButton
                                                onClick={() => {
                                                      history.push(InvSCOPathMap.SCO_INV_WAREHOUSE_LIST)
                                                }
                                                }>
                                                Back
                                          </CngSecondaryButton>
                                    </Grid>
                              </Grid>
                        </>
                  }

                  formikProps={
                        FormProperties.formikProps
                  }
                  toClientDataFormat={FormProperties.toClientDataFormat}
                  toServerDataFormat={FormProperties.toServerDataFormat}
                  fetch={{
                        url: SCOInvMgmtApiUrls.GET_WAREHOUE_BY_ID,
                        id: id
                  }}
                  id={id}

            />

      )

}

export default ViewForm