import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import { useFieldArray } from "react-hook-form"
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import { getPartyID } from 'src/store/intelligent-advisory'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import DefaultLocConfigTranslationText from './DefaultLocConfigTranslationText'

const {
    form: {
        field: { CngSelectField },
        adapter: {
            useFormAdapter: { useFormikContext }
        },
    },
    CngGridItem,
    button: { CngPrimaryButton }
} = components
const { NotificationType } = constants

const DEFAULT_INITIAL_VALUES = {
    locationList: []
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { showNotification, submittingData } = props;
    const fieldsTranslatedTextObject = DefaultLocConfigTranslationText()
    const { fields: locationList } = useFieldArray({ name: "locationList", keyName: 'key' });
    const partyId = getPartyID();
    const { fetchRecords } = useServices();
    const [nonStorageLocationOptions, setNonStorageLocationOptions] = useState([]);
    const { submitForm } = useFormikContext()
    const [fetchOptionsLoading, setFetchOptionsLoading] = useState(true);

    useEffect(() => {
        getNonStorageLocationList();
    }, [])

    const getNonStorageLocationList = () => {
        const onSuccess = (response) => {
            const options = response.content.map((loc) => {
                return { text: loc.locationName, value: loc.id }
            })
            setNonStorageLocationOptions(options);
            setFetchOptionsLoading(false);
        }

        fetchRecords.execute(
            SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST,
            {
                customData: {
                    baseFilterDTO: {
                        filterType: 'AND',
                        filterProperties: [
                            {
                                fieldName: 'partyId',
                                operatorType: 'EQUAL',
                                value1: partyId
                            },
                            {
                                fieldName: 'systemLocationCode',
                                operatorType: 'EQUAL',
                                value1: "NSTOR"
                            }
                        ],
                    }
                }
            },
            onSuccess,
            () => {
                showNotification(NotificationType.ERROR, "Failed to fetch location options");
                setFetchOptionsLoading(false);
            }
        );
    }

    return (
        <>
            <CngGridItem xs={12} sm={9} shouldHide={fetchOptionsLoading ? false : true}>
                <CngBackdrop loading={fetchOptionsLoading} />
            </CngGridItem>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.defaultLocations} hideMandatory />
                                <Box pt={5} pb={2} style={{ width: '100%' }} >
                                    <Grid container spacing={2}>
                                        <CngGridItem xs={12} sm={4}>
                                            <Typography variant='h4' className='font-bolder'>
                                                {fieldsTranslatedTextObject.movementType}
                                            </Typography>
                                        </CngGridItem>

                                        <CngGridItem xs={12} sm={4}>
                                            <Typography variant='h4' className='font-bolder'>
                                                {fieldsTranslatedTextObject.defaultLocation}
                                            </Typography>
                                        </CngGridItem>
                                        <CngGridItem xs={12} sm={4}>
                                        </CngGridItem>
                                    </Grid>
                                </Box>

                                {locationList.map((loc, index) => {
                                    return (
                                        <Box pt={2} style={{ width: '100%' }} key={loc.key}>
                                            <Grid container spacing={2}>
                                                <CngGridItem xs={12} sm={4} >
                                                    <Box pt={2} pb={2}>
                                                        <Typography variant='h4' className='font-bolder'>
                                                            {loc.movementType}
                                                        </Typography>
                                                    </Box>
                                                </CngGridItem>
                                                {!fetchOptionsLoading &&
                                                    <CngGridItem xs={12} sm={4}>
                                                        <CngSelectField
                                                            name={`locationList[${index}].locationId`}
                                                            label={fieldsTranslatedTextObject.locationName}
                                                            items={nonStorageLocationOptions}
                                                            required
                                                        />
                                                    </CngGridItem>
                                                }
                                            </Grid>
                                        </Box>
                                    );
                                })}
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>

            </Grid>
            <Box pt={3}>
                <CngPrimaryButton
                    disabled={submittingData}
                    endIcon={submittingData && <CircularProgress color='inherit' size={10} />}
                    style={{ marginRight: '10px' }}
                    type="button"
                    onClick={submitForm}
                >
                    {fieldsTranslatedTextObject.update}
                </CngPrimaryButton>
            </Box>

        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties