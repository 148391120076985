import {Box, Grid, Card} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import SCOInvMgmtApiUrls from "../../../apiUrls/SCOInvMgmtApiUrls";
import CecAccountApiUrls from "../../../apiUrls/CecAccountApiUrls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFormContext} from "react-hook-form";
import CngBackdrop from "../../vesselschedule/searchschedule/cngcomponent/CngBackDrop";
import makeValidationSchema from "../../sco-invmgmt/report/MakeValidationSchema";
import ReportListTable from "../../sco-invmgmt/report/components/ReportListTable";
import InvReportTranslationText from './InvReportTranslationText';

const {
	FormState,
	NotificationType,
	filter: {
	EQUAL
} } = constants

const {
	form: { CngForm,
		field: { CngSelectField, CngLookupAutocompleteField }
	},
	CngGridItem,
	table: { useDefaultNotification },
	button: { CngPrimaryButton }
}=components


function AddPage({ showNotification }) {

	const [dutyStatusList, setDutyStatusList] = useState(['None']);
	const [principalList, setPrincipalList] = useState(['None']);
	const { fetchRecords } = useServices();
	const [loading, setLoading] = useState(false);
	const [loadDutyStatus, setLoadDutyStatus] = useState(false);
	const [loadPrincipal, setLoadPrincipal] = useState(false);
	const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
	const partyId = getPartyID();
	const translatedTextsObject = InvReportTranslationText();

	const {
		success: showSuccessNotification,
		error: showErrorNotification
  	} = useDefaultNotification(showNotification)

	const renderId = useState('')
	const { createRecord } = useServices()

	useEffect(() => {
		getDutyStatusList()
		getPrincipalList()
	}, [])

	const getDutyStatusList = () => {
		setLoadDutyStatus(true)
		const onSuccess = (response) => {
			const list = response.content.map((duty) => {
				return { text: duty.name, value: duty.id }
			})
			setDutyStatusList(list)
			setLoadDutyStatus(false)
		}

		fetchRecords.execute(
			`${SCOInvMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/INV_DUTY_STATUS`,
			{},
			onSuccess,
			() => {
				setLoadDutyStatus(false)
				showNotification(NotificationType.ERROR, "Failed to fetch duty status")
			}
		)
	}

	const getPrincipalList = () => {
		setLoadPrincipal(true)
		const onSuccess = (response) => {
			const list = response.content.filter(p => p.partyId == partyId).map((principal) => {
				return { text: principal.displayName, value: principal.id }
			})
			setPrincipalList(list);
			setLoadPrincipal(false)
		}

		fetchRecords.execute(
			`${CecAccountApiUrls.GET_ACCOUNT_BY_PARTYID}`,
			{
				customData: {},
				filters: [
				  {
					field: 'accountMapping',
					operator: EQUAL,
					value: true
				  }
				]
			},
			onSuccess,
			() => {
				setLoadPrincipal(false)
				showNotification(NotificationType.ERROR, "Failed to fetch principal")
			}
		)
	}

	function onSubmit(input) {
		setLoading(true)

		let url = SCOInvMgmtApiUrls.EXPORT_REPORT;
		let data = new FormData();

		if(validateField(input))
			return

		data.append('principal', input.principal);
		data.append('dutyStatus', input.dutyStatus);
		data.append('location', input.locationId)

		function onSuccess(response) {
			setLoading(false)
			  if(response.errorMessages!=null){
					showErrorNotification(response.errorMessages)
			  }else{
					showSuccessNotification('Submitted Successfully, Please refresh and download the file from the table')
			  }
			  
		}

		function onError(error) {
			  console.log(error)
			  setLoading(false)
			  showErrorNotification(error.message)
		}

		createRecord.execute(
			url,
			data,
			onSuccess,
			onError
		)
  }

	function validateField(input) {
		if(input.principal == null || input.principal == "" || input.dutyStatus == null || input.dutyStatus == ""
			|| input.locationId == null || input.locationId == "") {
			showNotification(NotificationType.ERROR, "All fields are mandatory.")
			setLoading(false)
			return true
		}
		return false
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngForm
					formState={FormState.COMPLETED}
					formikProps={{
						initialValues: {
							principal:"",
							dutyStatus:"",
							locationId:"",
						},
						makeValidationSchema: makeValidationSchema,
						onSubmit : onSubmit
					}}
					renderBodySection={() => {
						return(
							<Grid container spacing={3}>
								<CngGridItem xs={12} sm={6} >
									<CngSelectField name="principal"
												  label={translatedTextsObject.principal}
												  items={principalList}
												  required
									/>
								</CngGridItem>

								<CngGridItem xs={12} sm={6} >
									<CngSelectField name="dutyStatus"
												  label={translatedTextsObject.dutyStatus}
												  items={dutyStatusList}
												  required
									/>
								</CngGridItem>

								<CngGridItem xs={12} sm={6}>
									<CngLookupAutocompleteField
										name={`locationId`}
										key={'location' + renderId}
										label={translatedTextsObject.warehouse}
										lookupProps={{
											url: SCOInvMgmtApiUrls.GET_LOCATION_LIST,
											label: 'locationName',
											value: 'locationId',
										}}
										required
									/>
								</CngGridItem>

								<CngGridItem xs={12} sm={9} shouldHide={loadDutyStatus || loadPrincipal ? false : true}>
									<CngBackdrop loading={loadDutyStatus || loadPrincipal } />
								</CngGridItem>
							</Grid>
							//* End JSX
						)
					}}

					renderButtonSection={() => (
						<SubmitButton
							onSubmit={onSubmit}
							loading={loading}
						/>
					)}
				/>
			</Grid>

			{/* Table Component */}
			<Grid item xs={12} sm={12}>
                <Box component={Card} py={2}>
					<ReportListTable  
						showNotification={showNotification}
					/>
                </Box>
            </Grid>
		</Grid>
	)
}

const SubmitButton = ({ onSubmit, loading }) => {

	const { handleSubmit } = useFormContext()

	return (
		<Box mt={2}>
			<CngPrimaryButton
				type='submit'
				onClick={handleSubmit(onSubmit)}
				disabled={loading}
				endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
			>
				Submit
			</CngPrimaryButton>
		</Box>
	);
}

export default AddPage
