import { components } from 'cng-web-lib'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import WarehouseStorageTable from './WarehouseStorageTable'
import EditForm from './EditForm'
import WarehouseTranslationText from './WarehouseTranslationText'

const {
	CngTabs
} = components

function EditPage({ showNotification }) {
	const { id } = useParams()
	const history = useHistory()
	const translatedTextObject = WarehouseTranslationText()

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextObject.warehouseDetailsTitle,
							tabContent: (
								<EditForm
									history={history}
									showNotification={showNotification}
									id={id}
								/>
							)
						},
						{
							tabName: translatedTextObject.warehouseStorageLocationTitle,
							tabContent: (
								<WarehouseStorageTable
									showNotification={showNotification}
									id={id}
									isViewOnly={false}
								/>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default EditPage
