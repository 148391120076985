import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components

function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = PrincipalMappingTranslationText();

      const columns = [
            {
                  field: 'operatorId',
                  title: translatedTextsObject.operator,
                  customLookup: () => {
                        return fetchCustomLookup(
                              SCOInvMgmtApiUrls.GET_PARTIES_BY_PRICIPALMAPPING,
                              {},
                              'content',
                              'partyName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: 'skuNum',
                  title: translatedTextsObject.skuNumber
            },
            {
                  field: 'skuDesc',
                  title: translatedTextsObject.skuDesc
            },
            {
                  field: "lotNumber",
                  title: translatedTextsObject.lotNumber
            },
            {
                  field: "quantity",
                  title: translatedTextsObject.quantity,
                  filtering: false
            },
            {
                  field: "mfgDate",
                  title: translatedTextsObject.mfgDate,
                  type: "date",
                  filtering: false
            },
            {
                  field: "expDate",
                  title: translatedTextsObject.expDate,
                  type: "date",
                  filtering: false
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    fetch={{ url: SCOInvMgmtApiUrls.GET_MY_INVENTORY_PRINCIPAL }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card>
      )
}

export default TablePage