import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const FIELDS_MAX_LENGTH = {
	CATEGORY_NAME: 255,
	ATTRIBUTE_NAME: 255
}


export function makeValidationSchema(translate) {
	let attrNameList = []

	Yup.addMethod(Yup.array, 'uniqueList', function (message) {
		return this.test('uniqueList', message, function (values) {
			let list = []
			if (values) {
				values.forEach((elem) => {
					if (list.includes(elem.attributeName)) {
						attrNameList.push(elem.attributeName)
					}
					else {
						list.push(elem.attributeName)
					}
					if (list.length === values.length) {
						attrNameList = []
					}
				})
			}
			return true;
		})
	})
	Yup.addMethod(Yup.string, 'uniqueAttrName', function (message) {
		return this.test('uniqueAttrName', message, function (val) {
			return !attrNameList.includes(val)
		})
	})

	const requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	const exceedCharMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCEED_CHAR_LIMIT_MSG)
	const duplicateAttrNameMsg = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUPLICATE_ATTR_NAME_MSG)

	return Yup.object({
		categoryName: Yup.string().nullable().required(requiredMessage).max(FIELDS_MAX_LENGTH.CATEGORY_NAME, exceedCharMsg),
		attributeConfigObjList: Yup.array(
			Yup.object().shape({
				attributeName: Yup.string().max(FIELDS_MAX_LENGTH.ATTRIBUTE_NAME, exceedCharMsg).required(requiredMessage)
					.uniqueAttrName(duplicateAttrNameMsg),
				inputTypeId: Yup.string().required(requiredMessage)
			})
		).uniqueList(""),
	})
}

