import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import FormProperties from './Supplier_FormProperties'
import SupplierTranslationText from './SupplierTranslationText'

const {
    form: {
        CngEditFormButtonSection
    },
    button: { CngSecondaryButton },
    CngGridItem
} = components

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {
    const { id } = useParams()
    const translatedTextObject = SupplierTranslationText()

    return (

        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            shouldDisableEnterToSubmit
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    id={id}
                />
            }
            renderButtonSection={() => (
                <CngEditFormButtonSection>
                    <CngGridItem>
                        <CngSecondaryButton
                            onClick={() => { history.push(pathMap.SCO_INV_SUPPLIER_LIST) }}>
                            {translatedTextObject.cancel}
                        </CngSecondaryButton>
                    </CngGridItem>
                </CngEditFormButtonSection>
            )}
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
                url: `${SCOInvMgmtApiUrls.GET_SUPPLIER_BY_ID}`
            }}
            update={{
                url: SCOInvMgmtApiUrls.UPDATE_SUPPLIER,
                successRedirect: pathMap.SCO_INV_SUPPLIER_LIST
            }}
            id={id}
        />
    )

}

export default EditPage
