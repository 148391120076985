import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'

import FormProperties from './Stock_FormProperties'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockHistoryTable from './StockHistoryTable'
import PrincipalMappingTranslationText from '../PrincipalMappingTranslationText'
import CommonTranslationText from '../../common/CommonTranslationText'

const { form: { CngViewForm },
	button: { CngSecondaryButton },
	CngTabs
} = components

function ViewPage({ history, showNotification, props }) {
	const { id } = useParams()
	const translatedTextObject = PrincipalMappingTranslationText()
	const commonText = CommonTranslationText();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextObject.stockDetails,
							tabContent: (
								<CngViewForm
									fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
									showNotification={showNotification}
									bodySection={
										<>
											<FormProperties.Fields
												disabled={true}
												showNotification={showNotification}
												id={id}
											/>

											<Grid container spacing={3}>
												<Grid item>
													<CngSecondaryButton
														onClick={() => { history.push(pathMap.SCO_INV_MY_PRINCIPAL_LIST) }}>
														{commonText.back}
													</CngSecondaryButton>
												</Grid>
											</Grid>
										</>
									}
									formikProps={FormProperties.formikProps}
									fetch={{
										id: id,
										url: `${SCOInvMgmtApiUrls.GET_STOCK_BY_ID}`
									}}

								/>
							)
						},
						{
							tabName: translatedTextObject.stockHistory,
							tabContent: (
								<CngViewForm
									fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
									showNotification={showNotification}
									bodySection={
										<>
											<Card>
												<CardContent>
													<Grid container>
														<AccordionHeaderComponent title={translatedTextObject.stockHistory} hideMandatory />
														<StockHistoryTable
															showNotification={showNotification}
															id={id}
															isViewOnly={true}
														/>
													</Grid>
												</CardContent>
											</Card>
											<CardContent >

											</CardContent>
											<Grid container spacing={3}>
												<Grid item>
													<CngSecondaryButton
														onClick={() => { history.push(pathMap.SCO_INV_MY_PRINCIPAL_LIST) }}>
														{translatedTextObject.back}
													</CngSecondaryButton>
												</Grid>
											</Grid>
										</>
									} //end of bodySection
									formikProps={FormProperties.formikProps}
									fetch={{
										id: id,
										url: `${SCOInvMgmtApiUrls.GET_STOCK_BY_ID}`
									}}
								/>
							)
						}
					]}
				/>
			</Grid>

		</Grid>

	)

}

export default ViewPage
