import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { makeValidationSchema } from './NonStorageLocation_ValidationSchema'
import NonStorageTranslationText from './NonStorageTranslationText'



const {
    form: {
        field: { CngTextField, CngSelectField },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    locationCode: "Non-Storage",
    locationName: "",
    partyId: ""
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const fieldsTranslatedTextObject = NonStorageTranslationText()
    const isAdmin = props.isAdmin

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.locationDetails} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={4} sm={4} shouldHide={!isAdmin}>
                                        <CngSelectField
                                            disabled={true}
                                            name="partyId"
                                            label={fieldsTranslatedTextObject.partyName}
                                            fetch={
                                                {
                                                    url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                                                    textAccessor: "name"
                                                }
                                            }
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={true}
                                            name="locationCode"
                                            label={fieldsTranslatedTextObject.locationCode}
                                            value={'Non-Storage'}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            disabled={disabled}
                                            name="locationName"
                                            label={fieldsTranslatedTextObject.locationName}
                                            required
                                        />

                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties