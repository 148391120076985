import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import BuyerTranslationText from './BuyerTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components

function AdminTablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextObject = BuyerTranslationText()

      const columns = [
            {
                  field: 'partyId',
                  title: translatedTextObject.partyName,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "buyerName",
                  title: translatedTextObject.buyerName
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    fetch={{ url: SCOInvMgmtApiUrls.ADMIN_GET_BUYER_LIST }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default AdminTablePage