import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import FormProperties from './Category_FormProperties'
import CategoryTranslationText from './CategoryTranslationText'

const { form: { CngViewForm },
	button: { CngSecondaryButton },
} = components

function AdminViewPage({ history, showNotification }) {
	const { id } = useParams()
	const translatedTextObject = CategoryTranslationText()

	return (
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			showNotification={showNotification}
			bodySection={
				<>
					<FormProperties.Fields
						disabled={true}
						showNotification={showNotification}
						id={id}
						isAdmin={true}
						isViewOnly={true}
					/>

					<Grid container spacing={3}>
						<Grid item>
							<CngSecondaryButton
								onClick={() => { history.push(pathMap.SCO_INV_ADMIN_CATEGORY_LIST) }}>
								{translatedTextObject.back}
							</CngSecondaryButton>
						</Grid>
					</Grid>
				</>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{
				id: id,
				url: `${SCOInvMgmtApiUrls.ADMIN_GET_CATEGORY_BY_ID}`
			}}
		/>
	)

}

export default AdminViewPage
