import { components, constants, useServices, useTranslation } from 'cng-web-lib';
import React, { useEffect, useRef, useState } from 'react';
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls';
import Namespace from 'src/constants/locale/Namespace';
import pathMap from 'src/paths/PathMap_SCO_InvMgmt';
import FormProperties from './ConfirmSkuDetails_FormProperties';
import { makeValidationSchema } from './ConfirmSkuDetails_ValidationSchema';

const {
    form: { CngAddForm },
} = components
const { NotificationType } = constants

function ConfirmSkuDetailsPage({ history, showNotification }) {
    const response = history?.location?.state?.response ?? {};
    const stateData = response.data ?? []
    const validRecordsCount = response.validRecordsCount ?? {}
    const dataUuid = response.dataUuid ?? {}
    const { createRecord } = useServices();
    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const [loading, setLoading] = useState(true);
    const [doNotProceedErrorMsg, setDoNotProceedErrorMsg] = useState(null);
    const [data, setData] = useState(stateData);
    const { securedSendRequest } = useServices()
    const [recordsCreated, setRecordsCreated] = useState(false);

    useEffect(() => {
        getProcessUploadStatus()
    }, [])

    const getProcessUploadStatus = () => {
        securedSendRequest.execute("GET", `${SCOInvMgmtApiUrls.GET_PROCESS_UPLOAD_STATUS}/${dataUuid}`, {},
            (res) => {
                // success
                let responseData = res.data;
                if (responseData) {
                    setLoading(false);
                    if (responseData.processingError) {
                        setDoNotProceedErrorMsg(`Please contact our admin, something went wrong with processing these following excel rows ${responseData.validExcelRowNumProcessingError}`);
                    }
                } else {
                    //call again to backend if no data comes back because not ready.
                    getProcessUploadStatus()
                }
            },
            (error) => {
                // error
                console.error(error)
                setLoading(false);
                setDoNotProceedErrorMsg("Please contact our admin, something went wrong.")
            },
            () => {
                // completed
            }
        )
    }

    const getCreateRecordsStatus = () => {
        securedSendRequest.execute("GET", `${SCOInvMgmtApiUrls.GET_CREATE_RECORDS_STATUS}/${dataUuid}`, {},
            (res) => {
                // success
                const responseData = res.data;

                if (responseData && responseData.complete) {
                    setRecordsCreated(true);
                    setLoading(false);

                    if (responseData.errorObjList.length > 0) {
                        setData(responseData.errorObjList);
                    } else {
                        history.push(pathMap.SCO_INV_SKU_LIST);
                    }
                } else {
                    //call again to backend if no data comes back because not ready.
                    getCreateRecordsStatus()
                }

            },
            (error) => {
                // error
                console.error(error)
                setLoading(false);
                setDoNotProceedErrorMsg("Please contact our admin, something went wrong.")
            },
            () => {
                // completed
            }
        )
    }


    function onSubmit(dataForm) {
        setLoading(true);
        setData([])
        const onSuccess = (res) => {
            if (res.data.length > 0) {
                showNotification(NotificationType.ERROR, "Not all SKUs successfully proccessed. Those which are not are returned with error messages.")
                setData(res.data);
                setLoading(false);
            } else {
                //start polling api for the creation of records
                getCreateRecordsStatus();
            }

        }
        const onError = (res) => {
            setLoading(false);
            showNotification(NotificationType.ERROR, "Something went wrong.")
        }

        createRecord.execute(
            SCOInvMgmtApiUrls.CREATE_SKU_FROM_UPLOAD,
            dataForm,
            onSuccess,
            onError
        )
    }

    return (
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    showNotification={showNotification}
                    data={data}
                    loading={loading}
                    history={history}
                    validRecordsCount={validRecordsCount}
                    doNotProceedErrorMsg={doNotProceedErrorMsg}
                    recordsCreated={recordsCreated}
                />
            }
            renderButtonSection={() => <></>}
            formikProps={{
                initialValues: { data: data, dataUuid: dataUuid },
                makeValidationSchema: () => makeValidationSchema(translate),
                onSubmit: onSubmit
            }}
        />
    )
}

export default ConfirmSkuDetailsPage
