import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import { getPartyID } from 'src/store/intelligent-advisory'
import SupplierTranslationText from './SupplierTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants

function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props
      const partyId = getPartyID();
      const translatedTextObject = SupplierTranslationText()
      const notification = useDefaultNotification(showNotification)

      const columns = [
            {
                  field: "supplierName",
                  title: translatedTextObject.supplierName
            },
            {
			field: 'createdDate', 
                  title: "",
			type: "datetime", 
                  defaultSort: 'desc',
			hidden: true
		}
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    addRoute={`${pathname}/create`}
                                    del={{ url: SCOInvMgmtApiUrls.DELETE_SUPPLIER }}
                                    editRoute={`${pathname}/edit`}
                                    fetchFilters={[{ field: 'partyId', operator: EQUAL, value: partyId }]}
                                    fetch={{ url: SCOInvMgmtApiUrls.GET_SUPPLIER }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )

}

export default TablePage