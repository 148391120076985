import { Box, Typography, makeStyles, } from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import React from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InvReportTranslationText from '../InvReportTranslationText'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      },
} = components

const {
      filter: {
            EQUAL, IN,
      }
} = constants

export const useStyles = makeStyles((theme) => ({
      font: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '.785rem'
      },
      red: {
            color: 'red'
      },
      green: {
            color: 'green'
      },
      btnSize:{
            fontSize: '1.25rem', 
      }, 
}))

function ReportListTable({showNotification}) {
      const classes = useStyles()
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = InvReportTranslationText();
      const { securedSendRequest } = useServices();
      
      const { 
            success: showSuccessNotification,
		error: showErrorNotification
      } = useDefaultNotification(showNotification)

      const onClickDownload = (rowData) => {
            securedSendRequest.execute( "POST", SCOInvMgmtApiUrls.DOWNLOAD_INV_REPORTS, rowData,
                  (response) => {
                        downloadFromS3(response.data)
                        showSuccessNotification("Download Completed")
                  },
                  (error) => {
                        console.log("Download Failed")
                        showErrorNotification("Download Failed")
                        console.log(error)
                  },
              )
      }

      function downloadFromS3(preSignedUrl) {
            let url = preSignedUrl
            let link = document.createElement('a')
            link.href = url;
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)

            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);            
      }


      function downloadIcon(){
            return   <FontAwesomeIcon icon={['fal', 'fa-download']}  className={clsx(classes.btnSize)} />   
      }

      const columns = [
            {
                  field: "fileName",
                  title: translatedTextsObject.fileNameFileType,
            },
            {
                  field: 'createdDate',
                  title: translatedTextsObject.createdDate,
                  type: "date",
                  filtering: false,
            },
            {
                  field: 'statusId',
                  title: translatedTextsObject.status,
                  sorting: false,
                  customLookup: () => {
                    return fetchCustomLookup(
                        `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                        {
                         "codeType" : "REPORT_GENERATE_STATUS"
                      },
                        'content',
                        'name', // label accessor
                        'id', // value accessor
                        (error) => console.error(error)
                    )
                  },
                  render:(rowData) =>{ 
                        if (rowData.status.code === "UPLOAD_COMPLETED") {
                              return <Typography className={clsx(classes.font, classes.green)}>{translatedTextsObject.uploadComplete}</Typography>
                        }
                        else if((rowData.status.code === "UPLOAD_FAILED")){
                              return <Typography className={clsx(classes.font, classes.red)}>{translatedTextsObject.uploadFailed}</Typography>
                        }
                  }
            
            },
            {
                  field: 'updatedDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }
      ]

      return (
            <Box>
                <CngCrudTable
                  fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                  columns={columns}
                  fetch={{ url: SCOInvMgmtApiUrls.GET_INV_REPORTS }}
                  idAccessor="id"
                  notification={useDefaultNotification(showNotification)}
                  del={{ url: SCOInvMgmtApiUrls.DELETE_INV_REPORT, }}
                  actions={[
                        {
                              name: 'download',
                              icon: (e) => downloadIcon(),
                              tooltip: 'Download File',
                              order: -1, //This is change the ordering of btns
                              onClick: (e, rowData) => {
                                    onClickDownload(rowData)
                              },
                              disableIf: (rowData) => {
                                    return rowData.s3FilePath == null
                              },
                              customTooltip: (rowData) => {
                                    return rowData.s3FilePath == null ? 'S3 link not found' : 'Download File'
                              }
                        }
                  ]}
                  authoriseActions= {{
                        execute: (actions) => {
                              return actions
                        }
                  }}

                  />
            </Box>
      )

}

export default ReportListTable