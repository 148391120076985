import { useTranslation } from 'cng-web-lib'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import Namespace from 'src/constants/locale/Namespace'
import CommonTranslationText from '../common/CommonTranslationText'

const StockTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const principal = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRINCIPAL)
    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const skuNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_NUMBER)
    const skuDesc = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DESC)
    const lotNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOT_NUMBER)
    const quantity = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.QUANTITY)
    const manufacturingDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MANUFACTURING_DATE)
    const expiryDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXPIRY_DATE)
    const asnNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ASN_NUMBER)
    const asnDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ASN_DATE)
    const grnNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.GRN_NUMBER)
    const grnDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.GRN_DATE)
    const actions = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ACTIONS)
    const stockDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_DETAILS)
    const stockHistory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_HISTORY)
    const skuDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_DETAILS)
    const skuCategory = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SKU_CATEGORY)
    const supplierSku = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER_SKU_NUM)
    const supplier = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.SUPPLIER)
    const stockAllocations = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.STOCK_ALLOCATIONS)
    const operator = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.OPERATOR)
    const location = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.LOCATION)
    const dutyStatus = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUTY_STATUS)
    const permitType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PERMIT_TYPE)
    const permitNumber = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PERMIT_NUMBER)
    const buyer = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BUYER)
    const updatedDate = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.UPDATED_DATE)
    const operationType = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.OPERATION_TYPE)
    const origin = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.ORIGIN)
    const destination = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DESTINATION)
    const inventoryList = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.INVENTORY_LIST)
    const dutyUnpaid = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUTY_UNPAID))
    const dutyPaid = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.DUTY_PAID))
    const inventoryCost = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.INVENTORY_COST))
    const purchasingInformation = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PURCHASING_INFORMATION))
    const currency = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CURRENCY))
    const productCost =(translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PRODUCT_COST))
    const importHandlingCost =(translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.IMPORT_HANDLING_COST))
    const freightCost = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.FREIGHT_COST))
    const insuranceCost = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.INSURANCE_COST))
    const totalLandedCost = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.TOTAL_LANDED_COST))
    const taxCode = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.TAX_CODE))
    const costDetails = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.COST_DETAILS))
    const overheadCost  = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.OVER_HEAD_COST))
    const customDuty = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CUSTOM_DUTY))
    const exciseDuty = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCISE_DUTY))
    const totalLandedCostNet = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.TOTAL_LANDED_COST_NET))
    const excludingDutiesAndTaxes = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCLUDING_DUTIES_AND_TAXES))
    const excludingDutiesAndTaxesDesc = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.EXCLUDING_DUTIES_AND_TAXES_DESC))
    const withDuties = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WITH_DUTIES))
    const withDutiesDesc = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WITH_DUTIES_DESC))
    const withDutiesAndTaxes = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WITH_DUTIES_AND_TAXES))
    const withDutiesAndTaxesDesc = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WITH_DUTIES_AND_TAXES_DESC))
    const weightedCost = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WEIGHTED_COST))
    const wacDU = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WEIGHTED_AVG_COST_DU))
    const currentValueDU = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CURRENT_VALUE_DU))
    const wacDP = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WEIGHTED_AVG_COST_DP))
    const currentValueDP = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CURRENT_VALUE_DP))
    const wacDUCal = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAC_DU_CAL))
    const currentValueDUCal = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CV_DU_CAL))
    const wacDPCal = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.WAC_DP_CAL))
    const currentValueDPCal = (translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.CV_DP_CAL))

    return{
        principal,
        moduleTitle,
        skuNumber,
        skuDesc,
        lotNumber,
        quantity,
        manufacturingDate,
        expiryDate,
        asnNumber,
        asnDate,
        grnNumber,
        grnDate,
        actions,
        stockDetails,
        stockHistory,
        skuDetails,
        skuCategory,
        supplierSku,
        supplier,
        stockAllocations,
        operator,
        location,
        dutyStatus,
        permitType,
        permitNumber,
        buyer,
        updatedDate,
        operationType,
        origin,
        destination,
        inventoryList,
        dutyUnpaid,
        dutyPaid,
        inventoryCost,
        purchasingInformation,
        currency,
        productCost,
        importHandlingCost,
        freightCost,
        insuranceCost,
        totalLandedCost,
        taxCode,
        costDetails,
        overheadCost,
        customDuty,
        exciseDuty,
        totalLandedCostNet,
        excludingDutiesAndTaxes,
        excludingDutiesAndTaxesDesc,
        withDutiesAndTaxes,
        withDutiesAndTaxesDesc,
        withDuties,
        withDutiesDesc,
        weightedCost,
        wacDU,
        currentValueDU,
        wacDP,
        currentValueDP,
        wacDUCal,
        currentValueDUCal, 
        wacDPCal,
        currentValueDPCal,
        ...commonText
    }



}
export default StockTranslationText