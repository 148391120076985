import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import SKUTranslationText from './SKUTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components

function AdminTablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextObject = SKUTranslationText()

      const columns = [
            {
                  field: 'partyId',
                  title: translatedTextObject.partyName,
                  customLookup: () => {
                        return fetchCustomLookup(
                              SCOInvMgmtApiUrls.GET_PARTY,
                              {},
                              'content',
                              'name',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: 'categoryId',
                  title: translatedTextObject.skuCategory,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.ADMIN_GET_CATEGORY_LIST}`,
                              {},
                              'content',
                              'categoryName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
                  field: "skuNum",
                  title: translatedTextObject.skuNumber
            },
            {
                  field: "skuDesc",
                  title: translatedTextObject.skuDesc
            },
            {
                  field: 'supplierId',
                  title: translatedTextObject.supplier,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOInvMgmtApiUrls.ADMIN_GET_SUPPLIER_LIST}`,
                              {},
                              'content',
                              'supplierName',
                              'id',
                              (error) => console.error(error)
                        )
                  }
            },
            {
			field: 'createdDate', 
                  title: "",
			type: "datetime", 
                  defaultSort: 'desc',
			hidden: true
		}
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    fetch={{ url: SCOInvMgmtApiUrls.ADMIN_GET_SKU_LIST }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default AdminTablePage