import { constants, useTranslation } from 'cng-web-lib'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import Namespace from 'src/constants/locale/Namespace'

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.CALISTA_UI_COMPONENT
]

const {
	locale: {
		key: { UiComponentKeys }
	}
} = constants

const CommonTranslationText = () => {

    const { translate } = useTranslation(translationNamespace);
    const create = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CREATE)
    const update = translate(Namespace.UI_COMPONENT, UiComponentKeys.EditForm.SUBMIT_BUTTON_TEXT)
    const cancel = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CANCEL)
    const edit = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.EDIT)
    const back = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.BACK)
    const updateSuccess = translate(Namespace.UI_COMPONENT, UiComponentKeys.Notification.UPDATE_SUCCESS_MESSAGE)
    const fetchError = translate(Namespace.UI_COMPONENT, UiComponentKeys.Notification.FETCH_ERROR_MESSAGE)
    const updateError = translate(Namespace.UI_COMPONENT, UiComponentKeys.Notification.UPDATE_ERROR_MESSAGE)
    
    return {
        create,
        update,
        cancel,
        edit,
        back,
        updateSuccess,
        fetchError,
        updateError
    }
}

export default CommonTranslationText
