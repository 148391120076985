import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import FormProperties from './Warehouse_FormProperties'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'
import { useHistory, useParams } from 'react-router-dom'

const {
      form: { CngEditForm },
      button: { CngPrimaryButton, CngSecondaryButton },
      table: {
            useDefaultNotification
      }
} = components


function EditForm({ history, showNotification, id }) {
      const [loading, setLoading] = useState(false);
      const { updateRecord } = useServices()


      return (

            <CngEditForm
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  history={history}
                  showNotification={showNotification}
                  bodySection={
                        <FormProperties.Fields
                              showNotification={showNotification}
                              id={id}
                              loading={loading}
                              disabled={false}
                        />
                  }
                  renderButtonSection={() => (
                        <>
                              <CngPrimaryButton style={{ marginRight: '10px' }}
                                    type='submit'
                              >
                                    Update
                              </CngPrimaryButton>
                              <CngSecondaryButton
                                    onClick={() => {
                                          history.push(InvSCOPathMap.SCO_INV_WAREHOUSE_LIST)
                                    }
                                    }>
                                    Cancel
                              </CngSecondaryButton>
                        </>
                  )}

                  formikProps={
                        FormProperties.formikProps
                  }
                  toClientDataFormat={FormProperties.toClientDataFormat}
                  toServerDataFormat={FormProperties.toServerDataFormat}
                  fetch={{ url: SCOInvMgmtApiUrls.GET_WAREHOUE_BY_ID }}
                  update={{
                        url: SCOInvMgmtApiUrls.UPDATE_WAREHOUSE,
                        successRedirect: InvSCOPathMap.SCO_INV_WAREHOUSE_LIST
                  }}
                  id={id}

            />

      )

}

export default EditForm