import { useTranslation } from 'cng-web-lib'
import SCOInvMgmtKeys from 'src/constants/locale/key/SCOInvMgmt'
import Namespace from 'src/constants/locale/Namespace'
import CommonTranslationText from '../common/CommonTranslationText'

const BuyerTranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.SCO_INV_MGMT);
    const moduleTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.MODULE_TITLE)
    const buyerName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BUYER_NAME)
    const partyName = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.PARTY_NAME)
    const buyerDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BUYER_DETAILS)
    const buyerAddress = translate(Namespace.SCO_INV_MGMT, SCOInvMgmtKeys.BUYER_ADDRESS)

    return {
        moduleTitle,
        buyerName,
        partyName,
        buyerDetails,
        buyerAddress,
        ...commonText
    }
}

export default BuyerTranslationText
