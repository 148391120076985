import { Box, Card, CardContent, Grid, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants, useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from "react-hook-form"
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import AttributeDialog from './AttributeDialog'
import UomDisplay from './formComponents/UomDisplay'
import SKUTranslationText from './SKUTranslationText'
import { makeValidationSchema } from './SKU_ValidationSchema'
import { getPartyID } from 'src/store/intelligent-advisory'

const INPUT_TYPE_CODES = {
    DATE: "DT",
    DATE_TIME: "DTTM",
    TEXT: "TXT",
    NUM: "NUM"
}
const {
    form: {
        field: { CngTextField, CngDateField, CngDateTimeField, CngSelectField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    categoryId: "",
    skuNum: "",
    supplierSkuNum: "",
    supplierId: "",
    skuDesc: "",
    attributeList: [],
    baseUomId: "",
    baseUomQty: "1",
    wholeUomId: "",
    wholeUomQty: "",
    partyId: "",
    uomList: []
}

const {
    filter: {
        EQUAL
    }
} = constants

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, isViewOnly, isAdmin, isCreate, showNotification, adminUrls } = props;
    const fieldsTranslatedTextObject = SKUTranslationText();
    const [attrConfigOptions, setAttrConfigOptions] = useState([]);
    const [filteredAttrConfigOptions, setFilteredAttrConfigOptions] = useState([]);
    const { fetchRecords } = useServices();
    const { getValues } = useFormContext();
    const values = getValues();
    const { setFieldValue } = useFormikContext();
    const { fields: uomList, remove: removeUom, append: appendUom } = useFieldArray({ name: "uomList", keyName: 'key' });
    const { fields: attributeList, remove: removeAttr } = useFieldArray({ name: "attributeList", keyName: 'key' });
    const [isOpen, setIsOpen] = useState(false);
    const partyId = getPartyID();

    const partyIdFilterArr = (partyIdFilter) => {
        if (!isAdmin) {
            return [
                {
                    field: 'partyId',
                    operator: EQUAL,
                    value: partyIdFilter
                }
            ]
        }
        return {};
    }

    useEffect(() => {
        if(props.data != undefined && props.data.length != 0) {
            handleCloneRecords();
        }
    },[props.data])

    useEffect(() => {
        if (!isViewOnly) {
            getAttributeNames();
        }
    }, [values.categoryId])

    const getAttributeNames = () => {
        const onSuccess = (response) => {
            const options = response.content.map((attr) => {
                return attr;
            })
            setAttrConfigOptions(options);
            let filteredAttr = options.filter(attrConfig => !attributeList.some(existingAttr => existingAttr.configDTO.id == attrConfig.id)).map((attrConfig) => {
                return { text: attrConfig.attributeName, value: attrConfig.id }
            });

            setFilteredAttrConfigOptions(filteredAttr);
        }

        fetchRecords.execute(
            SCOInvMgmtApiUrls.GET_CATEGORY_ATTRIBUTE_CONFIG_LIST,
            {
                filters: [
                    {
                        field: 'categoryId',
                        operator: 'EQUAL',
                        value: values.categoryId
                    }
                ]
            },
            onSuccess
        )
    }

    const handleCloneRecords = () => {
        props.data.categoryId != null && setFieldValue('categoryId', props.data.categoryId)
        props.data.skuNum != null && setFieldValue('skuNum', props.data.skuNum)
        props.data.supplierSkuNum != null && setFieldValue('supplierSkuNum',props.data.supplierSkuNum)
        props.data.supplierId != null && setFieldValue('supplierId',props.data.supplierId)
        props.data.skuDesc != null && setFieldValue('skuDesc',props.data.skuDesc)
        props.data.attributeList != null && setFieldValue('attributeList',props.data.attributeList)
        props.data.baseUomId != null && setFieldValue('baseUomId',props.data.baseUomId)
        props.data.baseUomQty != null && setFieldValue('baseUomQty','1')
        props.data.wholeUomId != null && setFieldValue('wholeUomId',props.data.wholeUomId)
        props.data.wholeUomQty != null && setFieldValue('wholeUomQty',props.data.wholeUomQty)
        props.data.partyId != null && setFieldValue('partyId',props.data.partyId)
        props.data.uomList != null && setFieldValue('uomList',props.data.uomList)
    }

    const handleRemoveAttribute = (index) => {
        const list = [...attributeList];
        list.splice(index, 1);
        setNewFilteredOptions(list);
        removeAttr(index);
    };

    const openAttributeDialog = () => {
        setIsOpen(true);
    }

    const setNewFilteredOptions = (newList) => {
        let filteredAttr = attrConfigOptions.filter(attrConfig => !newList.some(existingAttr => existingAttr.configDTO.id == attrConfig.id)).map((attrConfig) => {
            return { text: attrConfig.attributeName, value: attrConfig.id }
        });
        setFilteredAttrConfigOptions(filteredAttr);
    }

    const displayAttrValueField = (attribute, index) => {
        let code = attribute.configDTO.inputType.code;
        let field;
        switch (code) {
            case INPUT_TYPE_CODES.DATE:
                field = <CngDateField
                    name={`attributeList[${index}].value`}
                    label={fieldsTranslatedTextObject.value}
                    disabled={disabled}
                    required
                />
                break;
            case INPUT_TYPE_CODES.DATE_TIME:
                field = <CngDateTimeField
                    name={`attributeList[${index}].value`}
                    label={fieldsTranslatedTextObject.value}
                    disabled={disabled}
                    required
                />
                break;
            case INPUT_TYPE_CODES.NUM:
                field = <CngTextField
                    type="number"
                    disabled={disabled}
                    name={`attributeList[${index}].value`}
                    label={fieldsTranslatedTextObject.value}
                    required
                />
                break;
            default:
                field = <CngTextField
                    disabled={disabled}
                    name={`attributeList[${index}].value`}
                    label={fieldsTranslatedTextObject.value}
                    required
                />
        }
        return field;
    }


    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <AccordionHeaderComponent title={fieldsTranslatedTextObject.skuDetails} />
                            <Grid container spacing={2}>
                                <CngGridItem xs={12} sm={4} shouldHide={!isAdmin}>
                                    <CngSelectField
                                        disabled={true}
                                        name="partyId"
                                        label={fieldsTranslatedTextObject.partyName}
                                        fetch={
                                            {
                                                url: SCOInvMgmtApiUrls.GET_PARTY,
                                                textAccessor: "name"
                                            }
                                        }
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={4}>
                                    <CngLookupAutocompleteField
                                        shouldHide={isAdmin}
                                        disabled={!isCreate}
                                        key= {values.categoryId}
                                        name="categoryId"
                                        label={fieldsTranslatedTextObject.skuCategory}
                                        onChange={(e, val) => {
                                            values.categoryId = val.value
                                          }}
                                        lookupProps={{
                                            url: isAdmin ? adminUrls.category : SCOInvMgmtApiUrls.GET_CATEGORY,
                                            filters: partyIdFilterArr(partyId),
                                            label: 'categoryName',
                                            value: 'id'
                                        }}
                                        required
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={4}>
                                    <CngTextField
                                        disabled={!isCreate}
                                        name="skuNum"
                                        label={`${fieldsTranslatedTextObject.skuNumber}`}
                                        required
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={4}>
                                    <CngTextField
                                        disabled={disabled}
                                        name="supplierSkuNum"
                                        label={fieldsTranslatedTextObject.supplierSku}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={4}>
                                    <CngLookupAutocompleteField
                                        key={values.supplierId}
                                        name='supplierId'
                                        label={fieldsTranslatedTextObject.supplier}
                                        disabled={disabled}
                                        lookupProps={{
                                            url: isAdmin ? adminUrls.supplier : SCOInvMgmtApiUrls.GET_SUPPLIER,
                                            filters: partyIdFilterArr(partyId),
                                            label: 'supplierName',
                                            value: 'id'
                                        }}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={4}>
                                    <CngTextField
                                        disabled={disabled}
                                        name="skuDesc"
                                        label={fieldsTranslatedTextObject.skuDesc}
                                        required
                                    />
                                </CngGridItem>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
                <CngGridItem xs={12}>
                    <AttributeDialog
                        isOpen={isOpen}
                        setFieldValue={setFieldValue}
                        attributeList={attributeList}
                        setIsOpen={setIsOpen}
                        attrOptions={attrConfigOptions}
                        filteredAttrOptions={filteredAttrConfigOptions}
                        setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                        setNewFilteredOptions={setNewFilteredOptions}
                    />
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent hideMandatory={true} title={fieldsTranslatedTextObject.attributes} />
                                <Box mt={2} width={`100%`}>
                                    {isViewOnly && attributeList.length === 0 &&
                                        <CngGridItem xs={12}>
                                            <p>{"There are no attributes configured for this SKU."} </p>
                                        </CngGridItem>
                                    }
                                    {attributeList.map((attribute, index) => {
                                        return (
                                            <Box mb={2} width={`100%`} key={attribute.key}>
                                                <Grid container spacing={2}>
                                                    <CngGridItem xs={12} sm={4}>
                                                        <CngTextField
                                                            disabled={true}
                                                            name={`attributeList[${index}].configDTO.attributeName`}
                                                            label={fieldsTranslatedTextObject.attribute}
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} sm={4}>
                                                        {displayAttrValueField(attribute, index)}
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} sm={4}>
                                                        {!isViewOnly &&
                                                            <Box style={{ textAlign: 'right' }}>
                                                                <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveAttribute(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        }
                                                    </CngGridItem>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                    )}
                                    {!isViewOnly &&
                                        <CngGridItem xs={12} sm={4}>
                                            <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={disabled}
                                                onClick={() => openAttributeDialog()}>
                                                <AddCircleOutlineIcon
                                                    fontSize="small"
                                                    style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                                                />
                                                <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                                            </IconButton>
                                        </CngGridItem>
                                    }
                                </Box>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>

                <CngGridItem xs={12}>
                    <UomDisplay
                        isViewOnly={isViewOnly}
                        isCreate={isCreate}
                        setFieldValue={setFieldValue}
                        showNotification={showNotification}
                        uomList={uomList}
                        appendUom={appendUom}
                        removeUom={removeUom}
                        disabled={disabled}
                        wholeUomKey={values.wholeUomId}
                    />
                </CngGridItem>
            </Grid >
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}


const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties