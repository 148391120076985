import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

import {
      components,
      constants
} from 'cng-web-lib'

import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import NonStorageTranslationText from './NonStorageTranslationText'
import { getPartyID } from 'src/store/intelligent-advisory'



const {
      table: {
            CngCrudTable,
            useDefaultNotification,
      }
} = components

const {
      filter: {
            EQUAL
      }
} = constants


function TablePage(props) {
      const partyId = getPartyID();
      const {
            location: { pathname },
            showNotification
      } = props

      const translatedTextObject = NonStorageTranslationText()
      const notification = useDefaultNotification(showNotification)

      const columns = [
            {
                  field: "id",
                  title: translatedTextObject.locationRefId,
                  filtering: false,
                  sorting: false,
                  render: (rowData) => {
                        return 'LOCREF_' + rowData.id
                  }
            },
            {
                  field: "locationName",
                  title: translatedTextObject.locationName
            },
            {
                  field: 'createdDate',
                  title: "",
                  type: "datetime",
                  defaultSort: 'desc',
                  hidden: true
            }

      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    addRoute={`${pathname}/create`}
                                    fetchFilters={[
                                          {
                                                field: 'partyId',
                                                operator: EQUAL,
                                                value: partyId
                                          },
                                          {
                                                field: 'systemLocationCode',
                                                operator: EQUAL,
                                                value: 'NSTOR'
                                          }
                                    ]}
                                    fetch={{ url: SCOInvMgmtApiUrls.GET_NONSTORAGE_LOCATION_LIST }}

                                    del={{ url: SCOInvMgmtApiUrls.DELETE_NONSTORAGE_LOCATION }}
                                    editRoute={`${pathname}/edit`}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage