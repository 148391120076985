import { components, useServices } from 'cng-web-lib'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import InvSCOPathMap from 'src/paths/PathMap_SCO_InvMgmt'

import FormProperties from './NonStorageLocation_FormProperties'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
} = components

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {
    const { id } = useParams()



    return (

        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    id={id}
                />
            }
            renderButtonSection={() => (
                <>
                    <CngPrimaryButton style={{ marginRight: '10px' }}
                        type='submit'
                    >
                        Update
                    </CngPrimaryButton>
                    <CngSecondaryButton
                        onClick={() => {
                            history.push(InvSCOPathMap.SCO_INV_NONSTORAGE_LIST)
                        }
                        }>
                        Cancel
                    </CngSecondaryButton>
                </>
            )}
            formikProps={{
                ...FormProperties.formikProps,
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
                url: `${SCOInvMgmtApiUrls.GET_NONSTORAGE_BY_ID}`
            }}
            update={{
                url: SCOInvMgmtApiUrls.UPDATE_NONSTORAGE_LOCATION,
                successRedirect: InvSCOPathMap.SCO_INV_NONSTORAGE_LIST
            }}
            id={id}
        />
    )

}

export default EditPage
