import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import BuyerTranslationText from './BuyerTranslationText'
import FormProperties from './Buyer_FormProperties'

const { form: { CngViewForm },
	button: { CngPrimaryButton, CngSecondaryButton },
} = components

function ViewPage({ history, showNotification }) {
	const { id } = useParams()
	const translatedTextObject = BuyerTranslationText()

	return (
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			showNotification={showNotification}
			bodySection={
				<>
					<FormProperties.Fields
						disabled={true}
						showNotification={showNotification}
						id={id}
					/>

					<Grid container spacing={3}>
						<Grid item>
							<CngPrimaryButton style={{ marginRight: '10px' }}
								onClick={() => history.push(`${pathMap.SCO_INV_BUYER_LIST}/edit/${id}`)}>
								{translatedTextObject.edit}
							</CngPrimaryButton>
							<CngSecondaryButton
								onClick={() => { history.push(pathMap.SCO_INV_BUYER_LIST) }}>
								{translatedTextObject.back}
							</CngSecondaryButton>
						</Grid>
					</Grid>
				</>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{
				id: id,
				url: `${SCOInvMgmtApiUrls.GET_BUYER_BY_ID}`
			}}

		/>
	)

}

export default ViewPage
