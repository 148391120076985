import { Card, CardContent, Grid, Box } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import SKUTranslationText from './SKUTranslationText'
import FormProperties from './SKU_FormProperties'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import StockMovementHistoryTable from './StockMovementHistoryTable'

const { form: { CngViewForm },
	button: { CngPrimaryButton, CngSecondaryButton }, CngTabs
} = components

function ViewPage({ history, showNotification }) {
	const { id } = useParams()
	const translatedTextObject = SKUTranslationText()

	return (
	    <Grid container spacing={3}>
    			<Grid item xs={12}>
    				<CngTabs
    					headerColor='primary'
    					tabs={[
    						{
    							tabName: translatedTextObject.skuDetailsTab,
    							tabContent: (
    								<CngViewForm
                                    	fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                                    	showNotification={showNotification}
                                    	bodySection={
                                    		<>
                                    		<FormProperties.Fields
                                    			disabled={true}
                                    			showNotification={showNotification}
                                    			id={id}
                                    			isViewOnly={true}
                                    		/>

                                    		<Grid container spacing={3}>
                                    			<Grid item>
                                    				<CngPrimaryButton style={{ marginRight: '10px' }}
                                    					onClick={() => history.push(`${pathMap.SCO_INV_SKU_LIST}/edit/${id}`)}>
                                    					{translatedTextObject.edit}
                                    				</CngPrimaryButton>
                                    				<CngSecondaryButton
                                    					onClick={() => { history.push(pathMap.SCO_INV_SKU_LIST) }}>
                                    					{translatedTextObject.back}
                                    				</CngSecondaryButton>
                                    			</Grid>
                                    		</Grid>
                                    		</>
                                    	}
                                    	formikProps={FormProperties.formikProps}
                                    	toClientDataFormat={FormProperties.toClientDataFormat}
                                    	toServerDataFormat={FormProperties.toServerDataFormat}
                                    	fetch={{
                                    		id: id,
                                    		url: `${SCOInvMgmtApiUrls.GET_SKU_BY_ID}`
                                    	}}
                                    />
    							)
    						},
    						{
    							tabName: translatedTextObject.stockMovementHistory,
    							tabContent: (
    								<CngViewForm
                            			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            			showNotification={showNotification}
                            			bodySection={
                            				<>
    											<Card>
    												<CardContent>
    													<Grid container>
															<AccordionHeaderComponent title={translatedTextObject.skuStockMovementHistory} hideMandatory />
    														<StockMovementHistoryTable
    										    				showNotification={showNotification}
    										    				id={id}
    										    				isViewOnly={true}
    														/>
    													</Grid>
    												</CardContent>
    											</Card>
    											<CardContent >

    											</CardContent>
    											<Grid container spacing={3}>
                            						<Grid item>
                            							<CngSecondaryButton
                            								onClick={() => { history.push(pathMap.SCO_INV_SKU_LIST) }}>
                            								{translatedTextObject.back}
                            							</CngSecondaryButton>
                            						</Grid>
                            					</Grid>
                            				</>
                            			} //end of bodySection
										
                            			formikProps={FormProperties.formikProps}
    									fetch={{
    										url: `${SCOInvMgmtApiUrls.GET_STOCK_MOVEMENT_BY_SKUID}`
    									}}
                            		/>
    							)
    						}
    					]}
    				/>
    			</Grid>
    	</Grid>

	)

}

export default ViewPage
