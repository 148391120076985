import { Box, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Pagination from '@material-ui/lab/Pagination'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import SKUTranslationText from '../SKUTranslationText'

const {
    CngGridItem,
    button: { CngPrimaryButton, CngSecondaryButton },

} = components

export const ConfirmDetailsDisplay = (props) => {
    const { loading, fetchOptionsLoading, doNotProceedErrorMsg, recordsCreated, validRecordsCount, dataLength, recordsToDisplay, onUpdate, warningDialogOpen,
        discardWarningDialogOpen, setDiscardWarningDialogOpen, setWarningDialogOpen, totalPageCount, handlePageChange, page, history } = props;
    const fieldsTranslatedTextObject = SKUTranslationText();
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    return (
        <>
            <CngGridItem xs={12} sm={9} shouldHide={loading || fetchOptionsLoading > 0 ? false : true}>
                <CngBackdrop loading={loading || fetchOptionsLoading > 0} />
            </CngGridItem>
            {doNotProceedErrorMsg && <Alert severity="error">{doNotProceedErrorMsg}</Alert>}
            {!doNotProceedErrorMsg &&
                <>
                    {!recordsCreated && <Alert severity="success">{`${validRecordsCount} SKU records have been successfully processed.`}</Alert>}
                    {dataLength > 0 &&
                        <>
                            <Alert severity="error">
                                {`${recordsCreated ? `Not all SKUs have been successfully created. `: ``}Records with errors are listed below.`}
                            </Alert>
                            <Box pt={2} pb={2}>
                                <Pagination count={totalPageCount} page={page} onChange={handlePageChange} />
                            </Box>
                        </>
                    }
                    <Box pt={2} pb={2}>
                        <Grid container spacing={3}>
                            {recordsToDisplay}
                        </Grid >
                        <Box pt={5}>
                            <CngPrimaryButton
                                style={{ marginRight: '10px' }}
                                disabled={loading}
                                onClick={() => onUpdate()}
                            >
                                {"Create"}
                            </CngPrimaryButton>
                            <CngSecondaryButton
                                onClick={() => {
                                    setDiscardWarningDialogOpen(true)
                                }}>
                                {"Discard"}
                            </CngSecondaryButton>
                        </Box>
                    </Box>

                    <WarningDialog
                        isDialogOpen={warningDialogOpen}
                        closeDialog={() => setWarningDialogOpen(false)}
                        okDialog={() => setWarningDialogOpen(false)}
                        content={fieldsTranslatedTextObject.formError}
                        okMsg={uiTranslatedTextsObject.ok}
                    />

                    <WarningDialog
                        isDialogOpen={discardWarningDialogOpen}
                        closeDialog={() => setDiscardWarningDialogOpen(false)}
                        okDialog={() => { setDiscardWarningDialogOpen(false); history.push(pathMap.SCO_INV_SKU_UPLOAD) }}
                        content={`Are you sure you want to discard your changes and leave this page? ${!recordsCreated ? 'None of the records from the uploaded sheet will be saved.' : 'The records listed with errors here will not be saved.'}`}
                        okMsg={"Yes, Discard"}
                    />


                </>
            }
        </>
    )
}